.send-crypto-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #1e1e2f; /* Fondo oscuro */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #ffffff; /* Texto claro */
    font-family: Arial, sans-serif;
  }
  
  .send-crypto-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .send-crypto-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .send-crypto-field {
    display: flex;
    flex-direction: column;
  }
  
  .send-crypto-label {
    font-size: 14px;
    color: #bbbbbb; /* Texto secundario */
    margin-bottom: 5px;
  }
  
  .send-crypto-select,
  .send-crypto-input {
    background-color: #29293a; /* Fondo de los campos */
    color: #ffffff; /* Texto blanco */
    border: 1px solid #444; /* Borde sutil */
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    transition: border-color 0.3s ease;
  }
  
  .send-crypto-select:focus,
  .send-crypto-input:focus {
    border-color: #007bff; /* Borde azul al enfocar */
    outline: none;
  }
  
  .send-crypto-button {
    background-color: #007bff; /* Botón azul */
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .send-crypto-button:hover {
    background-color: #0056b3; /* Azul más oscuro al pasar el mouse */
  }
  
  .send-crypto-error {
    color: #ff4d4d; /* Rojo para errores */
    font-size: 14px;
    text-align: center;
    margin-top: -10px;
  }
  
  .send-crypto-success {
    color: #4caf50; /* Verde para éxito */
    font-size: 14px;
    text-align: center;
    margin-top: -10px;
  }
  