/* DashboardWithdraw.css */

/* body {
  background-color: #121212; 
  color: #e0e0e0; 
  font-family: 'Arial', sans-serif;
} */

.withdraw-container {
  background-color: #1e1e1e; /* Fondo del contenedor */
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  margin: 50px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

h2 {
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #bdbdbd;
}

.withdraw-container input,
.withdraw-container select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #333333;
  background-color: #1e1e1e;
  color: #e0e0e0;
}

.withdraw-container input::placeholder {
  color: #757575;
}


.withdraw-select-coin,
.withdraw-select-network {
  cursor: pointer;
}

.withdraw-input-amount,
.withdraw-input-destination {
  background-color: #262626;
}

.amount-container {
  display: flex;
  gap: 10px;
}

.max-button {
  padding: 10px 20px;
  background-color: #2d89ef; /* Azul claro */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.max-button:hover {
  background-color: #1b65c9;
}

.withdraw-button {
  width: 100%;
  padding: 15px;
  background-color: #00c853; /* Verde */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.withdraw-button:hover {
  background-color: #00b342;
}

.withdraw-info {
  font-size: 14px;
  color: #a5a5a5;
}

.withdraw-error {
  color: #ff5252;
  font-size: 14px;
}

.withdraw-message {
  margin-top: 20px;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}

.withdraw-message.success {
  background-color: #4caf50; /* Éxito */
  color: #ffffff;
}

.withdraw-message.error {
  background-color: #f44336; /* Error */
  color: #ffffff;
}


/* Estilo general del modal */
.confirmation-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: #fff;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Arial, sans-serif;
}

/* Fondo semi-transparente detrás del modal */
.confirmation-modal::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

/* Título del modal */
.confirmation-modal h3 {
  margin-bottom: 15px;
  font-size: 1.2em;
  color: #333;
}

/* Texto de detalles */
.confirmation-modal p {
  margin: 10px 0;
  font-size: 0.95em;
  color: #555555;
}

/* Contenedor de botones */
.confirmation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Botones */
.confirmation-buttons button {
  padding: 10px 20px;
  font-size: 0.9em;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirmation-buttons button:first-child {
  background-color: #28a745; /* Verde para Confirmar */
}

.confirmation-buttons button:first-child:hover {
  background-color: #218838;
}

.confirmation-buttons button:last-child {
  background-color: #dc3545; /* Rojo para Cancelar */
}

.confirmation-buttons button:last-child:hover {
  background-color: #c82333;
}
