/* Contenedor principal */
.transaction-list {
    padding: 20px;
    background-color: #121212;
    color: #fff;
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
}

/* Grupo de transacciones */
.transaction-group {
    margin-bottom: 20px;
}

/* Encabezado de fecha */
.transaction-date {
    color: #1e88e5;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 2px solid #333;
    padding-bottom: 5px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

/* Elemento de transacción */
.transaction-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    background-color: #1d1d1d;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
}

.transaction-item:hover {
    background-color: #282828;
}

/* Información de la transacción */
.transaction-info {
    display: flex;
    flex-direction: column;
}

.transaction-info strong {
    font-size: 16px;
    margin-bottom: 5px;
    color: #fff;
}

.transaction-info p {
    font-size: 14px;
    color: #aaa;
    margin: 0;
}

/* Monto de la transacción */
.transaction-amount {
    font-weight: bold;
    font-size: 16px;
    color: #00c853;
    text-align: right;
}

.transaction-amount.negative {
    color: #d32f2f;
}

/* Botón Ver Más */
.load-more-button {
    display: block;
    margin: 20px auto;
    padding: 12px 24px;
    background-color: #1e88e5;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.load-more-button:hover {
    background-color: #1565c0;
    transform: scale(1.05);
}

/* Mensaje vacío (si no hay transacciones) */
.no-transactions {
    text-align: center;
    font-size: 16px;
    color: #777;
    margin-top: 20px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
  }
  
  .modal-container {
    background: #1e1e1e;
    color: #fff;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    padding: 20px;
    position: relative;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  }
  
  .modal-header {
    display: flex;
    justify-content: flex-end;
  }
  
  .close-div {
    display: flex;
    justify-content: flex-end;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .close-button:hover {
    color: #e57373;
  }
  
  .modal-content {
    text-align: center;
  }
  
  .currency-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .modal-title-container {
    display: flex;
    flex-direction: column; /* Coloca los elementos verticalmente */
    align-items: center; /* Centra los elementos horizontalmente */
    margin-top: 10px; /* Espaciado superior opcional */
  }
  
  .modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px; /* Espacio entre el título y el subtítulo */
  }
  
  .modal-subtitle {
    font-size: 1.2rem;
    color: #aaa;
  }
  
  
  .modal-body {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .detail-item {
    display: flex;
    justify-content: space-between;
  }
  
  .detail-label {
    font-size: 0.9rem;
    color: #aaa;
  }
  
  .detail-value {
    font-size: 1rem;
    font-weight: bold;
  }
  
  .link {
    color: #1e88e5;
    cursor: pointer;
  }
  