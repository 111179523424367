.card-deposit-main {
  background-color: #121212; /* Fondo oscuro */
  color: #ffffff; /* Texto claro */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.card-deposit-container {
  background-color: #1e1e1e; /* Fondo de la tarjeta */
  border: 1px solid #333; /* Borde sutil */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* Sombra suave */
  padding: 20px;
  width: 100%;
  max-width: 400px;
}

.card-deposit-header {
  margin-bottom: 20px;
  text-align: center;
}

.card-deposit-title {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}

.card-deposit-description {
  font-size: 16px;
  color: #bbbbbb; /* Texto secundario */
}

.card-deposit-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.card-deposit-input-group {
  display: flex;
  flex-direction: column;
  flex: 1; /* Permite que los elementos ocupen el mismo ancho */
}

.card-deposit-label {
  font-size: 14px;
  color: #aaaaaa;
  margin-bottom: 5px;
}

.card-deposit-input,
.card-deposit-select {
  background-color: #292929; /* Fondo de los inputs */
  color: #ffffff; /* Texto claro */
  border: 1px solid #444; /* Borde sutil */
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.card-deposit-input:focus,
.card-deposit-select:focus {
  border-color: #007bff; /* Color de borde al enfocar */
  outline: none;
}

.card-deposit-row {
  display: flex;
  /* gap: 15px;  */
  /* flex-wrap: wrap;  */
}

.card-deposit-cost {
  font-size: 16px;
  color: #f1f1f1;
  text-align: center;
  margin-top: 10px;
}

.card-deposit-button {
  background-color: #007bff; /* Azul llamativo */
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s;
}

.card-deposit-button:hover {
  background-color: #0056b3; /* Azul más oscuro al pasar el mouse */
}
