/* Encabezado general */
.header {
    background: #1a1a2e; /* Azul oscuro */
    color: white;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    font-family: 'Roboto', sans-serif; /* Fuente moderna */
}

/* Contenedor del contenido del encabezado */
.header-content {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

/* Título clickable */
.header-title {
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease;
    margin: 0;
    color: #00aaff;
}

.header-title:hover {
    color: #ffcc00;
}

/* Menú horizontal */
.menu-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 30px;
}

/* Elementos del menú */
.menu-list li {
    position: relative;
}

/* Enlaces del menú */
.menu-list li a {
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.menu-list li a:hover,
.menu-list li a.active {
    background-color: #0056b3;
    color: #ffcc00;
}

/* Menú desplegable */
.vertical-menu {
    list-style: none;
    margin: 10px 0 0;
    padding: 10px 0;
    background-color: #333;
    border-radius: 8px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 100%;
    left: 0;
    display: none; /* Oculto por defecto */
    z-index: 10;
}

/* Mostrar menú al hover */
.menu-list li:hover .vertical-menu {
    display: block;
}

/* Elementos del menú desplegable */
.vertical-menu li a {
    text-decoration: none;
    color: white;
    padding: 10px 20px;
    display: block;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.vertical-menu li a:hover {
    background-color: #0056b3;
    color: #ffcc00;
}

/* Responsividad */
/* Responsividad para dispositivos móviles */
@media (max-width: 600px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 20px;
    }

    .header-title {
        font-size: 1.5rem;
        margin-bottom: 10px;
    }

    .menu-list {
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }

    .menu-list li a {
        font-size: 1rem;
        padding: 8px 12px;
    }

    .vertical-menu {
        width: 100%;
        padding: 5px 0;
    }
}
