.dashboard-change {
    font-family: Arial, sans-serif;
    color: white;
    background-color: black;
    padding: 30px;
    border-radius: 15px;
    width: 100%;
    max-width: 500px;
    margin: 40px auto;
    text-align: center;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.6);
}
/* 
h1 {
    font-size: 2.2rem;
    margin-bottom: 25px;
    color: #add8e6;
} */

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

label {
    font-size: 1.2rem;
}

.input-container {
    margin: 15px 0;
}

.menu-container {
    display: flex;
    align-items: center;
    border: 3px solid #6f00ff;
    border-radius: 8px;
    overflow: hidden;
}

select {
    border: none;
    background: transparent;
    color: white;
    padding: 15px;
    font-size: 1.2rem;
    outline: none;
    width: 60%;
}

.custom-input {
    border: none;
    background: transparent;
    color: white;
    padding: 15px;
    font-size: 1.2rem;
    outline: none;
    width: 40%;
    text-align: right;
}

input::placeholder {
    color: grey;
    opacity: 0.7;
}

.divider {
    width: 3px;
    height: 100%;
    background-color: #6f00ff;
}

.arrow {
    width: 60px;
    height: 60px;
    font-size: 2rem;
    border-radius: 50%;
    background-color: #6f00ff;
    color: white;
    border: none;
    margin: 25px auto;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.arrow:hover {
    background-color: #5800cc;
    transform: scale(1.2);
}

.preview-button {
    background-color: #6f00ff;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 1.2rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.preview-button:hover {
    background-color: #5800cc;
    transform: scale(1.1);
}

.preview-button:active {
    transform: scale(0.95);
}

select {
    border: none;
    background: transparent;
    color: white;
    padding: 15px;
    font-size: 1.2rem;
    outline: none;
    width: 60%;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
}

select option {
    background-color: black; /* Fondo oscuro para las opciones */
    color: white; /* Texto blanco */
}

select:focus {
    border: 3px solid #6f00ff;
}

select:hover {
    border-color: #ffffff;
}

/* Previsualización */
.preview-container {
    text-align: left;
    margin-top: 25px;
    padding: 25px;
    border: 3px solid #6f00ff;
    border-radius: 15px;
    background-color: #1a1a1a;
}

.exchange-rate {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    font-size: 1.2rem;
    color: #cccccc;
}

.amount-details {
    margin-top: 25px;
}

.amount-label {
    color: #aaaaaa;
    font-size: 1rem;
}

.amount-value {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 15px;
}

.amount-value.highlight {
    font-weight: bold;
    font-size: 1.8rem;
    color: #6f00ff;
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

.cancel-button {
    background-color: transparent;
    border: none;
    color: #ff0000;
    font-size: 1.2rem;
    cursor: pointer;
}

.confirm-button {
    background-color: #6f00ff;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 1.2rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.confirm-button:hover {
    background-color: #5800cc;
    transform: scale(1.1);
}


.currency-input {
    display: flex;
    align-items: center;
    gap: 10px; /* Espacio entre elementos */
  }
  
  .currency-input select,
  .currency-input input {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .currency-input select {
    min-width: 100px;
  }
  
  .currency-input input {
    flex: 1; /* Ocupa todo el espacio restante */
  }
  

  .marketplace-tab-single-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra los elementos horizontalmente */
    gap: 15px; /* Espaciado entre los elementos */
  }
  
  .swap-button,
  .convert-button {
    background-color: #007bff; /* Azul */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .swap-button:hover,
  .convert-button:hover {
    background-color: #0056b3; /* Azul más oscuro */
  }
  
  .swap-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .convert-button {
    width: 100%;
    max-width: 200px; /* Define un ancho máximo */
    text-align: center;
  }
  

  .marketplace-buttons {
    display: flex;
    gap: 10px;
  }
  
  .market-button, .limit-button {
    background: transparent;
    border: none;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    position: relative;
    cursor: pointer;
  }
  
  .market-button.active, .limit-button.active {
    border-bottom: 2px solid white;
  }
  
  .limit-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  #fromAmount {
    width: 100%;
    padding-right: 40px; /* Espacio para el Max */
  }
  
  .max-text {
    position: absolute;
    right: 10px;
    color: rgb(0, 0, 0);
    font-size: 14px;
    cursor: pointer;
    user-select: none;
  }
  
  .custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .custom-modal-content {
    background: #1e1e1e;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 300px;
  }
  
  .custom-modal-title {
    font-size: 18px;
    color: white;
    margin-bottom: 10px;
  }
  
  .custom-modal-text {
    font-size: 14px;
    color: #ccc;
    margin: 5px 0;
  }
  
  .custom-confirm-button,
  .custom-cancel-button {
    background: none;
    border: 1px solid white;
    color: white;
    padding: 8px 12px;
    margin: 10px 5px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .custom-confirm-button:hover {
    background: green;
    border-color: green;
  }
  
  .custom-cancel-button:hover {
    background: red;
    border-color: red;
  }
  