/* Dark theme styles for WalletReport component */

  
  .wallet-report-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #1e1e1e;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .wallet-report-title {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .wallet-report-error {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #ff5252;
    text-align: center;
  }
  
  .wallet-report-success {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #4caf50;
    text-align: center;
  }
  
  .wallet-report-dates {
    font-size: 1rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .wallet-report-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .wallet-report-input-group {
    display: flex;
    flex-direction: column;
  }
  
  .wallet-report-label {
    margin-bottom: 5px;
    font-size: 1rem;
    color: #bdbdbd;
  }
  
  .wallet-report-input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #424242;
    border-radius: 5px;
    background-color: #2c2c2c;
    color: #e0e0e0;
  }
  
  .wallet-report-input:focus {
    border-color: #2979ff;
    outline: none;
  }
  
  .wallet-report-button {
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #2979ff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .wallet-report-button:hover {
    background-color: #1565c0;
  }
  
  .wallet-report-loading {
    font-size: 1rem;
    text-align: center;
    color: #bdbdbd;
  }
  