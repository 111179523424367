

 

  
  .cryptocurrency-table-token {
    display: flex;
    align-items: center;
    gap: 8px; /* Menos espacio entre ícono y nombre */
  }
  
  .cryptocurrency-table-text-block {
    font-size: 13px; /* Más compacto */
    color: #d1d1d1;
  }
  
  .text-green {
    color: #4caf50;
  }
  
  .text-red {
    color: #f44336;
  }
  
  .text-orange {
    color: #ff9800;
  }
  
 