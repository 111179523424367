/* General Layout */
.content-dashboard {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #121212;
    font-family: Arial, sans-serif;
    gap: 20px;
    color: #e0e0e0; 
}

/* Top Section Layout */
.top-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.portfolio-summary {
    background: #1e1e1e; /* Fondo de los cuadros */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    padding: 20px;
    flex: 1;
}

.portfolio-summary .title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff;
}

/* Gráfica */
.chart-div {
    flex: 1;
}

.balance-total {
    flex: 1;
}

.balance-total .card {
    background: #1e1e1e; /* Fondo de tarjetas */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    padding: 20px;
}

.amount-title {
    font-size: 2rem;
    font-weight: bold;
    color: #ffffff; /* Texto principal */
}

.base-currency-title {
    font-size: 1rem;
    color: #b6b6b6; /* Texto secundario */
}

.primary-link {
    padding: 10px 20px;
    background-color: #4caf50; /* Verde para botones */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.primary-link:hover {
    background-color: #43a047;
}

/* My Portfolio Section */
.my-portfolio {
    background: #1e1e1e; /* Fondo oscuro */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    padding: 20px;
}

.my-portfolio .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #ffffff;
}

.coins-container {
    overflow-x: auto;
}

.coins-table {
    width: 100%;
    border-collapse: collapse;
}

.coins-table th {
    text-align: left;
    background-color: #2c2c2c; /* Fondo para encabezados */
    padding: 10px;
    font-weight: bold;
    color: #e0e0e0; /* Texto claro */
    border-bottom: 2px solid #444;
}

.coins-table td {
    padding: 10px;
    border-bottom: 1px solid #444;
    color: #b0b0b0; /* Texto de celdas */
}

.coins-table td .font-l {
    font-size: 1rem;
    color: #ffffff; /* Texto importante */
}

.coins-table td .font-xs {
    font-size: 0.8rem;
    color: #999; /* Texto secundario */
}

/* Responsive Design */
@media (max-width: 768px) {
    .content {
        padding: 10px;
    }

    .top-section {
        flex-direction: column;
    }

    .portfolio-summary,
    .balance-total {
        width: 100%;
        margin-bottom: 20px;
    }

    .coins-table th,
    .coins-table td {
        padding: 8px;
    }
}

.deposit-text {
    padding: 0;
    width: auto;
    margin-left: auto;
    outline: none; /* Quitar el contorno */
    cursor: pointer; /* Cambiar el cursor al pasar por encima */
    font-size: 1rem; /* Tamaño de la fuente */
    color: violet;
}

.total-container {
    display: flex;
    align-items: baseline;
}

.title-bal {
    color: #ffffff;
}





