/* changepass.css */

  
  .auth-change-password {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #1e1e1e;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  }
  
  .auth-change-password-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #ffffff;
  }
  
  .auth-form-group {
    margin-bottom: 15px;
  }
  
  .auth-label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    color: #b0b0b0;
  }
  
  .auth-input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    color: #ffffff;
    background-color: #333333;
    border: 1px solid #444444;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
  }
  
  .auth-input:focus {
    border-color: #bb86fc;
  }
  
  .auth-form-actions {
    text-align: center;
  }
  
  .auth-save-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #ffffff;
    background-color: #bb86fc;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .auth-save-button:disabled {
    background-color: #5a5a5a;
    cursor: not-allowed;
  }
  
  .auth-save-button:hover:not(:disabled) {
    background-color: #985ef9;
  }
  
  .auth-error-message,
  .auth-success-message {
    margin-top: 15px;
    font-size: 14px;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
  }
  
  .auth-error-message {
    background-color: #b00020;
    color: #ffffff;
  }
  
  .auth-success-message {
    background-color: #03dac5;
    color: #000000;
  }
  

  /* Modal dark theme */
.auth-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .auth-modal-content {
    background-color: #1e1e1e; /* Fondo oscuro */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    width: 90%;
    max-width: 400px;
    text-align: center;
    color: #ffffff; /* Texto claro */
  }
  
  .auth-modal-content h3 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #bb86fc; /* Color destacado para el título */
  }
  
  .auth-modal-content p {
    font-size: 16px;
    margin-bottom: 20px;
    color: #b0b0b0; /* Texto complementario */
  }
  
  .auth-input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    color: #ffffff;
    background-color: #333333;
    border: 1px solid #444444;
    border-radius: 5px;
    outline: none;
    margin-bottom: 20px;
    transition: border-color 0.3s ease-in-out;
  }
  
  .auth-input:focus {
    border-color: #bb86fc;
  }
  
  .auth-save-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #ffffff;
    background-color: #bb86fc;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .auth-save-button:hover {
    background-color: #985ef9;
  }
  
  .auth-modal-content button {
    width: 100%;
  }

  