:root {
    --dark-blue-0: #000;
    --paragraphs: #6f87a0eb;
    --white: white;
    --gradient: #bdd7f180;
    --dark-blue-3: #132436;
    --dark-blue-1: #050d15;
    --blue-2: #56f;
    --blue-3: #6472f3;
    --blue-1: #2a3ded;
    --dark-blue-2: #121f2d;
    --green-1: #186f44;
    --green-2: #35a670;
    --transparent-bg: #02090fcc;
    --paragraphs-dark: #6f87a099;
    --blur-blue: #2a3ded66;
    --circle-1: #2a3dedcc;
    --circle-2: #000000b3;
    --circle-border: #ffffff26;
    --circle-accent: #fff;
  }
  
  .w-layout-grid {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }
  
  .w-commerce-commerceaddtocartform {
    margin: 0 0 15px;
  }
  
  .w-commerce-commerceaddtocartoptionpillgroup {
    margin-bottom: 10px;
    display: flex;
  }
  
  .w-commerce-commerceaddtocartoptionpill {
    color: #000;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #000;
    margin-right: 10px;
    padding: 8px 15px;
  }
  
  .w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-selected {
    color: #fff;
    background-color: #000;
  }
  
  .w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-disabled {
    color: #666;
    cursor: not-allowed;
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    outline-style: none;
  }
  
  .w-commerce-commerceaddtocartquantityinput {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 60px;
    height: 38px;
    margin-bottom: 10px;
    padding: 8px 6px 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commerceaddtocartquantityinput::placeholder {
    color: #999;
  }
  
  .w-commerce-commerceaddtocartquantityinput:focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commerceaddtocartbutton {
    color: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    background-color: #3898ec;
    border-width: 0;
    border-radius: 0;
    align-items: center;
    padding: 9px 15px;
    text-decoration: none;
    display: flex;
  }
  
  .w-commerce-commerceaddtocartbutton.w--ecommerce-add-to-cart-disabled {
    color: #666;
    cursor: not-allowed;
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    outline-style: none;
  }
  
  .w-commerce-commercebuynowbutton {
    color: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    background-color: #3898ec;
    border-width: 0;
    border-radius: 0;
    align-items: center;
    margin-top: 10px;
    padding: 9px 15px;
    text-decoration: none;
    display: inline-block;
  }
  
  .w-commerce-commercebuynowbutton.w--ecommerce-buy-now-disabled {
    color: #666;
    cursor: not-allowed;
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    outline-style: none;
  }
  
  .w-commerce-commerceaddtocartoutofstock {
    background-color: #ddd;
    margin-top: 10px;
    padding: 10px;
  }
  
  .w-commerce-commerceaddtocarterror {
    background-color: #ffdede;
    margin-top: 10px;
    padding: 10px;
  }
  
  .w-commerce-commercecheckoutformcontainer {
    background-color: #f5f5f5;
    width: 100%;
    min-height: 100vh;
    padding: 20px;
  }
  
  .w-commerce-commercelayoutmain {
    flex: 0 800px;
    margin-right: 20px;
  }
  
  .w-commerce-commercecheckoutcustomerinfowrapper {
    margin-bottom: 20px;
  }
  
  .w-commerce-commercecheckoutblockheader {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    justify-content: space-between;
    align-items: baseline;
    padding: 4px 20px;
    display: flex;
  }
  
  .w-commerce-commercecheckoutblockcontent {
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    padding: 20px;
  }
  
  .w-commerce-commercecheckoutlabel {
    margin-bottom: 8px;
  }
  
  .w-commerce-commercecheckoutemailinput {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 0;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutemailinput::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutemailinput:focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutshippingaddresswrapper {
    margin-bottom: 20px;
  }
  
  .w-commerce-commercecheckoutshippingfullname {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 16px;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutshippingfullname::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutshippingfullname:focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutshippingstreetaddress {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 16px;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutshippingstreetaddress::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutshippingstreetaddress:focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutshippingstreetaddressoptional {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 16px;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutshippingstreetaddressoptional::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutshippingstreetaddressoptional:focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutrow {
    margin-left: -8px;
    margin-right: -8px;
    display: flex;
  }
  
  .w-commerce-commercecheckoutcolumn {
    flex: 1;
    padding-left: 8px;
    padding-right: 8px;
  }
  
  .w-commerce-commercecheckoutshippingcity {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 16px;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutshippingcity::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutshippingcity:focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutshippingstateprovince {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 16px;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutshippingstateprovince::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutshippingstateprovince:focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutshippingzippostalcode {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 16px;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutshippingzippostalcode::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutshippingzippostalcode:focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutshippingcountryselector {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 0;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutshippingcountryselector::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutshippingcountryselector:focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutshippingmethodswrapper {
    margin-bottom: 20px;
  }
  
  .w-commerce-commercecheckoutshippingmethodslist {
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
  }
  
  .w-commerce-commercecheckoutshippingmethoditem {
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 0;
    padding: 16px;
    font-weight: 400;
    display: flex;
  }
  
  .w-commerce-commercecheckoutshippingmethoddescriptionblock {
    flex-direction: column;
    flex-grow: 1;
    margin-left: 12px;
    margin-right: 12px;
    display: flex;
  }
  
  .w-commerce-commerceboldtextblock {
    font-weight: 700;
  }
  
  .w-commerce-commercecheckoutshippingmethodsemptystate {
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    padding: 64px 16px;
  }
  
  .w-commerce-commercecheckoutpaymentinfowrapper {
    margin-bottom: 20px;
  }
  
  .w-commerce-commercecheckoutcardnumber {
    -webkit-appearance: none;
    appearance: none;
    cursor: text;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 16px;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutcardnumber::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutcardnumber:focus, .w-commerce-commercecheckoutcardnumber.-wfp-focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutcardexpirationdate {
    -webkit-appearance: none;
    appearance: none;
    cursor: text;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 16px;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutcardexpirationdate::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutcardexpirationdate:focus, .w-commerce-commercecheckoutcardexpirationdate.-wfp-focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutcardsecuritycode {
    -webkit-appearance: none;
    appearance: none;
    cursor: text;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 16px;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutcardsecuritycode::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutcardsecuritycode:focus, .w-commerce-commercecheckoutcardsecuritycode.-wfp-focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutbillingaddresstogglewrapper {
    flex-direction: row;
    display: flex;
  }
  
  .w-commerce-commercecheckoutbillingaddresstogglecheckbox {
    margin-top: 4px;
  }
  
  .w-commerce-commercecheckoutbillingaddresstogglelabel {
    margin-left: 8px;
    font-weight: 400;
  }
  
  .w-commerce-commercecheckoutbillingaddresswrapper {
    margin-top: 16px;
    margin-bottom: 20px;
  }
  
  .w-commerce-commercecheckoutbillingfullname {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 16px;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutbillingfullname::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutbillingfullname:focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutbillingstreetaddress {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 16px;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutbillingstreetaddress::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutbillingstreetaddress:focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutbillingstreetaddressoptional {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 16px;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutbillingstreetaddressoptional::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutbillingstreetaddressoptional:focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutbillingcity {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 16px;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutbillingcity::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutbillingcity:focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutbillingstateprovince {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 16px;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutbillingstateprovince::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutbillingstateprovince:focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutbillingzippostalcode {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 16px;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutbillingzippostalcode::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutbillingzippostalcode:focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutbillingcountryselector {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
    height: 38px;
    margin-bottom: 0;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutbillingcountryselector::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutbillingcountryselector:focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutorderitemswrapper {
    margin-bottom: 20px;
  }
  
  .w-commerce-commercecheckoutsummaryblockheader {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    justify-content: space-between;
    align-items: baseline;
    padding: 4px 20px;
    display: flex;
  }
  
  .w-commerce-commercecheckoutorderitemslist {
    margin-bottom: -20px;
  }
  
  .w-commerce-commercecheckoutorderitem {
    margin-bottom: 20px;
    display: flex;
  }
  
  .w-commerce-commercecartitemimage {
    width: 60px;
    height: 0%;
  }
  
  .w-commerce-commercecheckoutorderitemdescriptionwrapper {
    flex-grow: 1;
    margin-left: 16px;
    margin-right: 16px;
  }
  
  .w-commerce-commercecheckoutorderitemquantitywrapper {
    white-space: pre-wrap;
    display: flex;
  }
  
  .w-commerce-commercecheckoutorderitemoptionlist {
    margin-bottom: 0;
    padding-left: 0;
    text-decoration: none;
    list-style-type: none;
  }
  
  .w-commerce-commercelayoutsidebar {
    flex: 0 0 320px;
    position: sticky;
    top: 20px;
  }
  
  .w-commerce-commercecheckoutordersummarywrapper {
    margin-bottom: 20px;
  }
  
  .w-commerce-commercecheckoutsummarylineitem, .w-commerce-commercecheckoutordersummaryextraitemslistitem {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
    display: flex;
  }
  
  .w-commerce-commercecheckoutsummarytotal {
    font-weight: 700;
  }
  
  .w-commerce-commercecheckoutdiscounts {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    flex-wrap: wrap;
    padding: 20px;
    display: flex;
  }
  
  .w-commerce-commercecheckoutdiscountslabel {
    flex-basis: 100%;
    margin-bottom: 8px;
  }
  
  .w-commerce-commercecheckoutdiscountsinput {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 3px;
    flex: 1;
    width: auto;
    min-width: 0;
    height: 38px;
    margin-bottom: 0;
    padding: 8px 12px;
    line-height: 20px;
    display: block;
  }
  
  .w-commerce-commercecheckoutdiscountsinput::placeholder {
    color: #999;
  }
  
  .w-commerce-commercecheckoutdiscountsinput:focus {
    border-color: #3898ec;
    outline-style: none;
  }
  
  .w-commerce-commercecheckoutdiscountsbutton {
    color: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    background-color: #3898ec;
    border-width: 0;
    border-radius: 3px;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    height: 38px;
    margin-left: 8px;
    padding: 9px 15px;
    text-decoration: none;
    display: flex;
  }
  
  .w-commerce-commercecartapplepaybutton {
    color: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    background-color: #000;
    border-width: 0;
    border-radius: 2px;
    align-items: center;
    height: 38px;
    min-height: 30px;
    margin-bottom: 8px;
    padding: 0;
    text-decoration: none;
    display: flex;
  }
  
  .w-commerce-commercecartapplepayicon {
    width: 100%;
    height: 50%;
    min-height: 20px;
  }
  
  .w-commerce-commercecartquickcheckoutbutton {
    color: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    background-color: #000;
    border-width: 0;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    height: 38px;
    margin-bottom: 8px;
    padding: 0 15px;
    text-decoration: none;
    display: flex;
  }
  
  .w-commerce-commercequickcheckoutgoogleicon, .w-commerce-commercequickcheckoutmicrosofticon {
    margin-right: 8px;
    display: block;
  }
  
  .w-commerce-commercecheckoutplaceorderbutton {
    color: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    text-align: center;
    background-color: #3898ec;
    border-width: 0;
    border-radius: 3px;
    align-items: center;
    margin-bottom: 20px;
    padding: 9px 15px;
    text-decoration: none;
    display: block;
  }
  
  .w-commerce-commercecheckouterrorstate {
    background-color: #ffdede;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 10px 16px;
  }
  
  .w-commerce-commercepaypalcheckoutformcontainer {
    background-color: #f5f5f5;
    width: 100%;
    min-height: 100vh;
    padding: 20px;
  }
  
  .w-commerce-commercecheckoutcustomerinfosummarywrapper {
    margin-bottom: 20px;
  }
  
  .w-commerce-commercecheckoutsummaryitem, .w-commerce-commercecheckoutsummarylabel {
    margin-bottom: 8px;
  }
  
  .w-commerce-commercecheckoutsummaryflexboxdiv {
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
  }
  
  .w-commerce-commercecheckoutsummarytextspacingondiv {
    margin-right: .33em;
  }
  
  .w-commerce-commercecheckoutpaymentsummarywrapper {
    margin-bottom: 20px;
  }
  
  .w-commerce-commercepaypalcheckouterrorstate {
    background-color: #ffdede;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 10px 16px;
  }
  
  .w-commerce-commerceorderconfirmationcontainer {
    background-color: #f5f5f5;
    width: 100%;
    min-height: 100vh;
    padding: 20px;
  }
  
  .w-commerce-commercecheckoutshippingsummarywrapper {
    margin-bottom: 20px;
  }
  
  @media screen and (max-width: 767px) {
    .w-commerce-commercelayoutmain {
      flex-basis: auto;
      margin-right: 0;
    }
  
    .w-commerce-commercelayoutsidebar {
      flex-basis: auto;
    }
  }
  
  @media screen and (max-width: 479px) {
    .w-commerce-commerceaddtocartquantityinput, .w-commerce-commercecheckoutemailinput, .w-commerce-commercecheckoutshippingfullname, .w-commerce-commercecheckoutshippingstreetaddress, .w-commerce-commercecheckoutshippingstreetaddressoptional {
      font-size: 16px;
    }
  
    .w-commerce-commercecheckoutrow {
      flex-direction: column;
    }
  
    .w-commerce-commercecheckoutshippingcity, .w-commerce-commercecheckoutshippingstateprovince, .w-commerce-commercecheckoutshippingzippostalcode, .w-commerce-commercecheckoutshippingcountryselector, .w-commerce-commercecheckoutcardnumber, .w-commerce-commercecheckoutcardexpirationdate, .w-commerce-commercecheckoutcardsecuritycode, .w-commerce-commercecheckoutbillingfullname, .w-commerce-commercecheckoutbillingstreetaddress, .w-commerce-commercecheckoutbillingstreetaddressoptional, .w-commerce-commercecheckoutbillingcity, .w-commerce-commercecheckoutbillingstateprovince, .w-commerce-commercecheckoutbillingzippostalcode, .w-commerce-commercecheckoutbillingcountryselector, .w-commerce-commercecheckoutdiscountsinput {
      font-size: 16px;
    }
  }
  
  body {
    background-color: var(--dark-blue-0);
    color: var(--paragraphs);
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    font-family: Creato Display, sans-serif;
    font-size: 16px;
    line-height: 1.62em;
    display: flex;
  }
  
  h1 {
    background-image: linear-gradient(140deg, var(--white), var(--gradient));
    color: var(--white);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 4.75em;
    font-weight: 500;
    line-height: 1.06em;
  }
  
  h2 {
    background-image: linear-gradient(140deg, var(--white), var(--gradient));
    color: var(--white);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 3.37em;
    font-weight: 500;
    line-height: 1.07em;
  }
  
  h3 {
    background-image: linear-gradient(140deg, var(--white), var(--gradient));
    color: var(--white);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2.37em;
    font-weight: 500;
    line-height: 1.1em;
  }
  
  h4 {
    background-image: linear-gradient(140deg, var(--white), var(--gradient));
    color: var(--white);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.62em;
    font-weight: 500;
    line-height: 1.16em;
  }
  
  h5 {
    background-image: linear-gradient(140deg, var(--white), var(--gradient));
    color: var(--white);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.2em;
  }
  
  h6 {
    background-image: linear-gradient(140deg, var(--white), var(--gradient));
    color: var(--white);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.12em;
    font-weight: 500;
    line-height: 1.28em;
  }
  
  p {
    letter-spacing: .025em;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  a {
    text-decoration: underline;
  }
  
  ul, ol {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    padding-left: 2.2em;
  }
  
  li {
    letter-spacing: .025em;
    padding: .3em .6em;
  }
  
  strong {
    font-weight: bold;
  }
  
  em {
    font-style: italic;
  }
  
  blockquote {
    border-left: 1px solid var(--dark-blue-3);
    background-image: linear-gradient(140deg, var(--white), var(--gradient));
    color: var(--white);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    margin-left: .9em;
    padding: 0 0 0 1.4em;
    font-size: 1.25em;
    font-style: italic;
    font-weight: 500;
    line-height: 1.6em;
  }
  
  figure {
    border: 1px solid var(--dark-blue-3);
    border-radius: 1.2em;
    margin-top: 2.7em;
    margin-bottom: 2.7em;
    overflow: hidden;
  }
  
  figcaption {
    background-color: var(--dark-blue-1);
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    padding: 1.2em 8%;
    font-size: .87em;
    line-height: 1.5em;
  }
  
  .link {
    border-bottom: 1px solid var(--blue-2);
    color: var(--white);
    text-decoration: none;
    transition: color .2s cubic-bezier(.25, .46, .45, .94);
  }
  
  .link:hover {
    color: var(--blue-2);
  }
  
  .rich-text-block a {
    border-bottom: 1px solid var(--blue-2);
    color: var(--white);
    text-decoration: none;
    transition: color .2s cubic-bezier(.25, .46, .45, .94);
  }
  
  .rich-text-block a:hover {
    color: var(--blue-2);
  }
  
  .rich-text-block p {
    margin-bottom: 1.3em;
  }
  
  .rich-text-block h1 {
    margin-bottom: .4em;
  }
  
  .rich-text-block h2 {
    margin-top: .9em;
    margin-bottom: .5em;
  }
  
  .rich-text-block h3, .rich-text-block h4, .rich-text-block h5, .rich-text-block h6 {
    margin-top: .9em;
    margin-bottom: .6em;
  }
  
  .nav-block {
    border: 1px solid var(--dark-blue-3);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: #000000b3;
    border-radius: 50em;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 74em;
    padding: .6em .65em;
    display: flex;
  }
  
  .navbar {
    z-index: 500;
    background-color: #0000;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .7em 2.2em 0;
    display: flex;
    position: fixed;
    inset: 0% 0% auto;
  }
  
  .nav {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .brand {
    filter: grayscale(90%);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: .6em;
    margin-right: .6em;
    padding: .5em .4em;
    transition: filter .2s cubic-bezier(.25, .46, .45, .94);
    display: flex;
  }
  
  .brand:hover {
    filter: grayscale(0%);
  }
  
  .brand.w--current {
    position: relative;
    right: 0;
  }
  
  .logo {
    flex: none;
    max-width: 100%;
    height: 1.6em;
    text-decoration: none;
  }
  
  .nav-menu {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .nav-link {
    color: var(--paragraphs);
    text-align: center;
    letter-spacing: .025em;
    padding: .7em 1.1em;
    font-size: .94em;
    font-weight: 500;
    line-height: 1.2em;
    transition: color .2s cubic-bezier(.25, .46, .45, .94);
  }
  
  .nav-link:hover {
    color: var(--white);
  }
  
  .nav-link.w--current {
    color: var(--paragraphs);
  }
  
  .nav-link.w--current:hover {
    color: var(--white);
  }
  
  .block-nav-button {
    grid-column-gap: .55em;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .nav-link-extra {
    color: var(--paragraphs);
    text-align: center;
    letter-spacing: .025em;
    padding: .7em 1.1em;
    font-size: .94em;
    font-weight: 500;
    line-height: 1.2em;
    transition: color .2s cubic-bezier(.25, .46, .45, .94);
  }
  
  .nav-link-extra:hover {
    color: var(--white);
  }
  
  .nav-button {
    background-color: var(--dark-blue-2);
    color: var(--white);
    text-align: center;
    letter-spacing: .025em;
    border-radius: 50em;
    padding: .7em 1.4em;
    font-size: .94em;
    font-weight: 500;
    line-height: 1.2em;
    transition: box-shadow .4s cubic-bezier(.25, .46, .45, .94);
  }
  
  .nav-button:hover {
    box-shadow: 0 5px 40px -10px var(--blue-2), inset -15px 13px 20px 0 var(--blue-3), inset 20px -55px 20px 0 var(--blue-1);
  }
  
  .section-hero {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 13em 1.8em 7em;
    display: flex;
    position: relative;
  }
  
  .content {
    width: 100%;
    max-width: 75em;
  }
  
  .block-hero {
    z-index: 50;
    grid-row-gap: 2.4em;
    text-align: left;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    position: relative;
  }
  
  .heading-hero {
    max-width: 12em;
    margin-bottom: -.15em;
  }
  
  .heading-hero.large {
    font-size: 6em;
    line-height: 1em;
  }
  
  .paragraph {
    max-width: 25em;
    font-size: 1.125em;
    line-height: 1.7em;
  }
  
  .button {
    background-color: #0000;
    background-image: linear-gradient(20deg, var(--blue-1), var(--blue-3));
    box-shadow: 0 7px 80px -12px var(--blue-2), inset 0 0 0 0 #13243600, inset 0 0 0 0 #13243600;
    color: var(--white);
    text-align: center;
    letter-spacing: .025em;
    border-radius: 50em;
    padding: .9em 1.8em;
    font-size: 1.12em;
    font-weight: 500;
    line-height: 1.2em;
    transition: box-shadow .4s cubic-bezier(.25, .46, .45, .94);
  }
  
  .button:hover {
    box-shadow: 0 0 0 -10px var(--blue-2), inset -15px 13px 30px 0 var(--dark-blue-3), inset 20px -55px 30px 0 var(--dark-blue-3);
  }
  
  .button.green {
    background-image: linear-gradient(20deg, var(--green-1), var(--green-2));
    box-shadow: 0 7px 80px -12px var(--green-2), inset 0 0 0 0 #13243600, inset 0 0 0 0 #13243600;
  }
  
  .button.green:hover {
    box-shadow: 0 0 0 -10px var(--green-2), inset -15px 13px 30px 0 var(--dark-blue-3), inset 20px -55px 30px 0 var(--dark-blue-3);
  }
  
  .wrapper {
    background-color: var(--dark-blue-3);
    perspective: 1300px;
    border-radius: 1.2em;
    align-items: stretch;
    width: 100%;
    padding: 1px;
    display: flex;
    position: relative;
    overflow: hidden;
  }
  
  .content-wrapper {
    z-index: 10;
    background-color: var(--transparent-bg);
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    border-radius: 1.2em;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  
  .blurred-color {
    z-index: 0;
    background-color: var(--blue-2);
    opacity: 0;
    filter: blur(30px);
    border-radius: 100%;
    width: 70%;
    height: 70%;
    margin: auto;
    position: absolute;
    inset: 0%;
  }
  
  .blurred-color.small-blur {
    filter: blur(5px);
  }
  
  .blurred-color.green {
    background-color: var(--green-2);
  }
  
  .section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5.3em 1.8em;
    display: flex;
    position: relative;
  }
  
  .section.first {
    padding-top: 1.4em;
  }
  
  .section.post {
    padding-top: 2em;
    padding-bottom: 4em;
  }
  
  .grid-features {
    grid-column-gap: 1.25em;
    grid-row-gap: 1.25em;
    grid-template-rows: auto;
    place-items: stretch stretch;
  }
  
  .feature {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    display: flex;
  }
  
  .image-feature {
    border-radius: 1em;
    width: 100%;
  }
  
  .paragraph-feature {
    max-width: 24.5em;
  }
  
  .heading-feature {
    max-width: 12em;
    margin-bottom: -.25em;
  }
  
  .block-heading {
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 3.5em;
    display: flex;
  }
  
  .heading {
    max-width: 12.8em;
  }
  
  .heading.opacity {
    opacity: .3;
    max-width: 17em;
  }
  
  .subtitle {
    color: var(--blue-2);
    letter-spacing: .13em;
    text-transform: uppercase;
    background-color: #5566ff26;
    border-radius: 50em;
    margin-bottom: 1.5em;
    padding: .7em 1.3em;
    font-size: .63em;
    font-weight: 500;
    line-height: 1.3em;
  }
  
  .section-clients {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3em 1.8em 5.3em;
    display: flex;
    position: relative;
  }
  
  .subtitle-clients {
    color: var(--paragraphs);
    text-align: center;
    letter-spacing: .13em;
    text-transform: uppercase;
    margin-bottom: 3em;
    font-size: .63em;
    font-weight: 500;
    line-height: 1.2em;
  }
  
  .block-clients {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    position: relative;
    overflow: hidden;
  }
  
  .clients {
    flex-direction: row;
    flex: none;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }
  
  .logo-client {
    max-height: 2.7em;
    margin-left: 2.5em;
    margin-right: 2.5em;
  }
  
  .gradient-clients {
    z-index: 50;
    background-image: linear-gradient(to right, var(--dark-blue-0) 5%, #070f1700 20%, #070f1700 80%, var(--dark-blue-0) 95%);
    position: absolute;
    inset: 0%;
  }
  
  .button-small {
    background-color: #0000;
    background-image: linear-gradient(20deg, var(--blue-1), var(--blue-3));
    box-shadow: 0 7px 80px -12px var(--blue-2), inset 0 0 0 0 #13243600, inset 0 0 0 0 #13243600;
    color: var(--white);
    text-align: center;
    letter-spacing: .025em;
    border-radius: 50em;
    padding: .8em 1.6em;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.2em;
    transition: box-shadow .4s cubic-bezier(.25, .46, .45, .94);
  }
  
  .button-small:hover {
    box-shadow: 0 0 0 -10px var(--blue-2), inset -15px 13px 30px 0 var(--dark-blue-3), inset 20px -55px 30px 0 var(--dark-blue-3);
  }
  
  .button-small.green {
    background-image: linear-gradient(20deg, var(--green-1), var(--green-2));
    box-shadow: 0 7px 80px -12px var(--green-2), inset 0 0 0 0 #13243600, inset 0 0 0 0 #13243600;
  }
  
  .button-small.green:hover {
    box-shadow: 0 0 0 -10px var(--green-2), inset -15px 13px 30px 0 var(--dark-blue-3), inset 20px -55px 30px 0 var(--dark-blue-3);
  }
  
  .grid {
    grid-column-gap: 2.5em;
    grid-row-gap: 2.5em;
    grid-template-rows: auto;
    place-items: stretch stretch;
  }
  
  .block-heading-left {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }
  
  .paragraph-hero {
    max-width: 33em;
    font-size: 1.125em;
    line-height: 1.7em;
  }
  
  .paragraph-hero.max-w {
    max-width: 21em;
  }
  
  .icon-list {
    width: 1.5em;
    height: 1.5em;
  }
  
  .list {
    grid-column-gap: 1em;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }
  
  .block-list {
    grid-row-gap: .5em;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 25em;
    display: flex;
  }
  
  .heading-list {
    margin-top: .05em;
  }
  
  .block {
    z-index: 50;
    grid-row-gap: 2.2em;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 3.7em;
    padding-bottom: 3.7em;
    display: flex;
    position: relative;
  }
  
  .block.right {
    padding-left: 10%;
  }
  
  .grid-tools {
    grid-column-gap: 1.8em;
    grid-row-gap: 2.4em;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  
  .tool {
    grid-row-gap: 1.2em;
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
  }
  
  .heading-tool {
    margin-bottom: -.2em;
  }
  
  .paragraph-tool {
    max-width: 16em;
  }
  
  .icon-tool {
    width: 2em;
    height: 2em;
    margin: 1em;
  }
  
  .grid-plan {
    grid-column-gap: 1.25em;
    grid-row-gap: 1.25em;
    grid-template-rows: auto;
    align-items: start;
  }
  
  .plan {
    grid-row-gap: 2.2em;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3.3em 3.7em 3.7em;
    display: flex;
  }
  
  .price-plan {
    font-size: 3.87em;
    line-height: .9em;
  }
  
  .block-price-plan {
    grid-column-gap: .7em;
    justify-content: flex-start;
    align-items: flex-end;
    display: flex;
  }
  
  .info-price-plan {
    color: var(--paragraphs);
    text-align: center;
    letter-spacing: .13em;
    text-transform: uppercase;
    margin-bottom: .5em;
    font-size: .63em;
    font-weight: 500;
    line-height: 1.2em;
  }
  
  .border {
    background-color: var(--paragraphs);
    opacity: .15;
    width: 100%;
    height: 1px;
  }
  
  .list-plan {
    grid-row-gap: 1.1em;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: .4em;
    padding-top: .3em;
    padding-bottom: .3em;
    display: flex;
  }
  
  .heading-plan {
    margin-bottom: -.8em;
  }
  
  .paragraph-plan {
    max-width: 25em;
    font-size: 1.125em;
    line-height: 1.7em;
  }
  
  .grid-benefits {
    grid-column-gap: 1.25em;
    grid-row-gap: 1.25em;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 1.25em;
  }
  
  .benefit {
    grid-row-gap: .9em;
    border: 1px solid var(--dark-blue-3);
    text-align: center;
    border-radius: 1.2em;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2em;
    display: flex;
  }
  
  .icon-benefit {
    width: 1.5em;
    height: 1.5em;
  }
  
  .heading-benefit {
    margin-bottom: -.3em;
  }
  
  .paragraph-benefit {
    max-width: 16em;
  }
  
  .grid-reviews {
    grid-column-gap: 1.25em;
    grid-row-gap: 1.25em;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: start;
  }
  
  .block-reviews {
    grid-row-gap: 1.25em;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    display: flex;
  }
  
  .review {
    grid-row-gap: 1.5em;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2.7em 3.2em;
    display: flex;
  }
  
  .avatar-review {
    border-radius: 100%;
    width: 2.9em;
    height: 2.9em;
  }
  
  .review-info {
    grid-column-gap: .9em;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }
  
  .review-subtitle {
    letter-spacing: .025em;
    font-size: .87em;
    line-height: 1.2em;
  }
  
  .block-review-info {
    grid-row-gap: .2em;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: flex;
  }
  
  .section-footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    padding: 5.3em 1.8em 0;
    display: flex;
    position: relative;
  }
  
  .grid-footer {
    grid-column-gap: 1.4em;
    grid-row-gap: 0em;
    grid-template: ". . . Area"
    / 2.4fr 1fr 1fr 1fr;
    margin-bottom: 8.3em;
  }
  
  .logo-footer {
    filter: grayscale(90%);
    height: 1.6em;
    margin-bottom: 1.8em;
  }
  
  .block-footer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }
  
  .paragraph-footer {
    color: var(--paragraphs-dark);
    max-width: 22em;
    margin-bottom: 1.6em;
  }
  
  .block-footer-down {
    text-align: center;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.8em;
    display: flex;
    position: relative;
    right: -650px;
  }
  
  .text-footer-down {
    color: var(--paragraphs-dark);
    text-align: center;
    letter-spacing: .025em;
    font-size: .94em;
    line-height: 1.4em;
    position: relative;
    right: 220px;
  }
  
  .link-footer-down {
    color: var(--paragraphs);
    font-weight: 500;
    text-decoration: none;
    transition: color .2s cubic-bezier(.25, .46, .45, .94);
  }
  
  .link-footer-down:hover {
    color: var(--white);
  }
  
  .link-footer {
    color: var(--paragraphs);
    padding-top: .25em;
    padding-bottom: .25em;
    font-weight: 500;
    text-decoration: none;
    transition: color .2s cubic-bezier(.25, .46, .45, .94);
  }
  
  .link-footer:hover {
    color: var(--white);
  }
  
  .subtitle-footer {
    color: var(--paragraphs-dark);
    text-align: center;
    letter-spacing: .13em;
    text-transform: uppercase;
    margin-bottom: 2.2em;
    font-size: .63em;
    font-weight: 500;
    line-height: 1.2em;
  }
  
  .link-block {
    grid-column-gap: .5em;
    background-color: var(--dark-blue-2);
    color: var(--paragraphs);
    border-radius: .94em;
    justify-content: flex-start;
    align-items: center;
    padding: .7em 1.2em;
    font-weight: 500;
    line-height: 1.25em;
    text-decoration: none;
    transition: box-shadow .4s cubic-bezier(.25, .46, .45, .94), color .4s cubic-bezier(.25, .46, .45, .94);
    display: flex;
  }
  
  .link-block:hover {
    box-shadow: 0 5px 50px -12px var(--blue-2), inset -15px 13px 20px 0 var(--blue-3), inset 20px -55px 20px 0 var(--blue-1);
    color: var(--white);
  }
  
  .icon-link {
    mix-blend-mode: screen;
    width: 1.25em;
    height: 1.25em;
  }
  
  .banner {
    perspective: 1300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4.6em 3.7em;
    display: flex;
    position: relative;
  }
  
  .block-banner {
    z-index: 70;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
  }
  
  .heading-banner {
    max-width: 11em;
    margin-bottom: .6em;
  }
  
  .paragraph-banner {
    max-width: 25em;
    margin-bottom: 2.2em;
    font-size: 1.125em;
    line-height: 1.7em;
  }
  
  .image-banner {
    z-index: 5;
    width: 37%;
    position: absolute;
    inset: auto 0% 0% auto;
  }
  
  .image-banner.img-2 {
    inset: 0% auto auto 0%;
  }
  
  .section-top {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 11em 1.8em 3em;
    display: flex;
    position: relative;
  }
  
  .block-top {
    grid-row-gap: 2.4em;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .block-heading-top {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .paragraph-block {
    max-width: 34em;
    margin-top: 2em;
    font-size: 1.125em;
    line-height: 1.7em;
  }
  
  .grid-faq {
    grid-column-gap: .9em;
    grid-row-gap: .9em;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    width: 100%;
    max-width: 54em;
    margin-left: auto;
    margin-right: auto;
  }
  
  .accordion-item {
    border: 1px solid var(--dark-blue-3);
    background-color: var(--dark-blue-1);
    border-radius: 1.2em;
    padding-left: 1.8em;
    padding-right: 1.8em;
  }
  
  .accordion-header {
    grid-column-gap: 1em;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    margin-right: -.7em;
    padding-top: 1.4em;
    padding-bottom: 1.4em;
    transition: padding .3s cubic-bezier(.25, .46, .45, .94);
    display: flex;
  }
  
  .accordion-header:hover {
    padding-left: .35em;
    padding-right: .35em;
  }
  
  .icon-accordion {
    flex: none;
    width: 1.5em;
    height: 1.5em;
  }
  
  .accordion-content {
    display: block;
    overflow: hidden;
  }
  
  .paragraph-accordion {
    max-width: 90%;
    margin-bottom: 1.6em;
  }
  
  .collection-list {
    grid-column-gap: 1.8em;
    grid-row-gap: 1.8em;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }
  
  .collection-item {
    justify-content: flex-start;
    align-items: stretch;
    display: flex;
  }
  
  .block-post {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    padding: 3.7em;
    display: flex;
  }
  
  .image-post-thumbnail {
    object-fit: cover;
    border-radius: 1em;
    width: 100%;
    height: 18em;
  }
  
  .link-post-thumbnail {
    border: 1px solid #0000;
    border-radius: 1em;
    width: 100%;
    margin-bottom: 2.6em;
    transition: border-color .4s cubic-bezier(.25, .46, .45, .94);
  }
  
  .link-post-thumbnail:hover {
    border-color: var(--blue-2);
  }
  
  .link-post {
    margin-bottom: 2.4em;
    text-decoration: none;
  }
  
  .date {
    color: var(--paragraphs);
    text-align: center;
    letter-spacing: .13em;
    text-transform: uppercase;
    margin-bottom: 1em;
    font-size: .63em;
    font-weight: 500;
    line-height: 1.2em;
  }
  
  .link-circle {
    background-color: var(--dark-blue-2);
    color: var(--paragraphs);
    border-radius: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 2.75em;
    height: 2.75em;
    text-decoration: none;
    transition: color .4s cubic-bezier(.25, .46, .45, .94), box-shadow .4s cubic-bezier(.25, .46, .45, .94);
    display: flex;
    position: relative;
  }
  
  .link-circle:hover {
    box-shadow: 0 5px 50px -12px var(--blue-2), inset -15px 13px 20px 0 var(--blue-3), inset 20px -55px 20px 0 var(--blue-1);
    color: var(--white);
  }
  
  .text-link-circle {
    min-width: 8ch;
    font-weight: 500;
    line-height: 1.25em;
    position: absolute;
    left: 3.4em;
  }
  
  .icon-circle {
    mix-blend-mode: screen;
    width: 1.5em;
    height: 1.5em;
  }
  
  .post-button {
    margin-top: auto;
  }
  
  .empty-state {
    border: 1px solid var(--dark-blue-3);
    text-align: center;
    background-color: #0000;
    border-radius: 1.2em;
    padding: 1.4em;
  }
  
  .empty-state.invisible {
    display: none;
  }
  
  .text-empty {
    letter-spacing: .025em;
    font-weight: 500;
    line-height: 1.2em;
  }
  
  .image-main-post {
    border: 1px solid var(--dark-blue-3);
    object-fit: cover;
    border-radius: 1.2em;
    width: 100%;
    margin-bottom: 4.3em;
  }
  
  .content-narrow {
    width: 100%;
    max-width: 48.7em;
    margin-left: auto;
    margin-right: auto;
  }
  
  .content-narrow.template {
    grid-row-gap: 9em;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    display: flex;
  }
  
  .block-contact {
    width: 100%;
    max-width: 49.5em;
    margin-left: auto;
    margin-right: auto;
  }
  
  .contact {
    padding: 3.7em;
  }
  
  .form-block {
    margin-bottom: 0;
  }
  
  .form {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    display: flex;
  }
  
  .field-label {
    color: var(--paragraphs);
    letter-spacing: .13em;
    text-transform: uppercase;
    margin-bottom: .9em;
    font-size: .63em;
    font-weight: 500;
    line-height: 1.2em;
  }
  
  .color-label {
    color: var(--blue-2);
  }
  
  .text-field {
    border: 1px solid var(--dark-blue-3);
    color: var(--white);
    letter-spacing: .025em;
    background-color: #050b1180;
    border-radius: .62em;
    height: 3.75em;
    margin-bottom: 1.6em;
    padding: .8em 1.12em;
    font-size: 1em;
    line-height: 1.3em;
    transition: border-color .3s cubic-bezier(.25, .46, .45, .94);
  }
  
  .text-field:focus {
    border-color: var(--blue-2);
  }
  
  .text-field::placeholder {
    color: var(--paragraphs-dark);
  }
  
  .field {
    text-align: left;
    flex: 1;
    width: 100%;
  }
  
  .field-group {
    grid-column-gap: 1.5em;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    display: flex;
  }
  
  .textarea {
    border: 1px solid var(--dark-blue-3);
    color: var(--white);
    letter-spacing: .025em;
    background-color: #050b1180;
    border-radius: .62em;
    min-width: 100%;
    max-width: 100%;
    min-height: 16em;
    max-height: 30em;
    margin-bottom: 1.6em;
    padding: 1.1em 1.12em;
    font-size: 1em;
    line-height: 1.5em;
    transition: border-color .3s cubic-bezier(.25, .46, .45, .94);
  }
  
  .textarea:focus {
    border-color: var(--blue-2);
  }
  
  .textarea::placeholder {
    color: var(--paragraphs-dark);
  }
  
  .submit-button {
    background-color: #0000;
    background-image: linear-gradient(20deg, var(--blue-1), var(--blue-3));
    box-shadow: 0 7px 80px -12px var(--blue-2), inset 0 0 0 0 #13243600, inset 0 0 0 0 #13243600;
    color: var(--white);
    text-align: center;
    letter-spacing: .025em;
    border-radius: .62em;
    margin-top: 1.2em;
    padding: 1em 1.8em;
    font-size: 1.12em;
    font-weight: 500;
    line-height: 1.2em;
    transition: box-shadow .4s cubic-bezier(.25, .46, .45, .94);
  }
  
  .submit-button:hover {
    box-shadow: 0 0 0 -10px var(--blue-2), inset -15px 13px 30px 0 var(--dark-blue-3), inset 20px -55px 30px 0 var(--dark-blue-3);
  }
  
  .success-message {
    background-color: #35a67026;
    border-radius: 1em;
    padding: 1.4em;
  }
  
  .text-success {
    color: var(--green-2);
    letter-spacing: .025em;
    font-weight: 500;
  }
  
  .error-message {
    background-color: #0000;
    border: 1px solid #e74c5f;
    border-radius: .62em;
    margin-top: 1.6em;
    padding: 1em 1.2em;
  }
  
  .text-error {
    color: #e74c5f;
    letter-spacing: .025em;
    font-weight: 500;
  }
  
  .account {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    padding: 7em 1em 4em;
    display: flex;
    position: relative;
    overflow: hidden;
  }
  
  .brand-account {
    z-index: 100;
    filter: grayscale(90%);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: .5em .4em;
    transition: filter .2s cubic-bezier(.25, .46, .45, .94);
    display: flex;
    position: absolute;
    top: 1.4em;
  }
  
  .brand-account:hover {
    filter: grayscale(0%);
  }
  
  .brand-account.w--current {
    flex: none;
  }
  
  .block-account {
    width: 100%;
    max-width: 33.7em;
    margin-left: auto;
    margin-right: auto;
  }
  
  .account-form {
    padding: 3.7em;
  }
  
  .block-heading-account {
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2.5em;
    display: flex;
  }
  
  .text-account {
    color: var(--paragraphs-dark);
    text-align: center;
    letter-spacing: .025em;
    margin-top: 2.2em;
    font-size: .94em;
    line-height: 1.4em;
  }
  
  .link-account {
    color: var(--paragraphs);
    font-weight: 500;
    text-decoration: none;
    transition: color .2s cubic-bezier(.25, .46, .45, .94);
  }
  
  .link-account:hover {
    color: var(--white);
  }
  
  .paragraph-form {
    max-width: 22em;
    margin-top: 1.5em;
  }
  
  .grid-account {
    grid-column-gap: 0em;
    grid-row-gap: 0em;
    grid-template-rows: auto;
    grid-template-columns: 1fr 2.2fr;
  }
  
  .sing-up-banner {
    padding: 1em;
    display: flex;
  }
  
  .img-sing-up-banner {
    border: 1px solid var(--dark-blue-3);
    background-image: url('../images/Sing-up_1Sing up.webp');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1.2em;
    width: 100%;
  }
  
  .utility-page-wrap {
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    padding: 7em 1em 4em;
    display: flex;
    position: relative;
    overflow: hidden;
  }
  
  .utility-page-content {
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 22em;
    margin-bottom: 0;
    display: flex;
  }
  
  .utility-page-form {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    display: flex;
  }
  
  .cart {
    flex: none;
    margin-left: .5em;
  }
  
  .cart-wrapper {
    background-color: #0000;
  }
  
  .cart-container {
    border: 1px solid var(--dark-blue-3);
    background-color: var(--dark-blue-1);
    border-radius: 1.2em;
    width: 100%;
    min-width: 22em;
    max-width: 30em;
    margin-top: 1em;
  }
  
  .cart-header {
    border-bottom-color: var(--dark-blue-3);
    padding: 1em 1.5em;
  }
  
  .cart-footer {
    border-top-color: var(--dark-blue-3);
    padding: 1em 1.5em 1.5em;
  }
  
  .close-button {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    padding: .2em;
    display: flex;
  }
  
  .icon-close {
    object-fit: cover;
    width: 1.5em;
    height: 1.5em;
  }
  
  .cart-list {
    padding: 1em 1.5em;
  }
  
  .cart-item {
    align-items: flex-start;
    padding-top: .7em;
    padding-bottom: .7em;
  }
  
  .image-cart-item {
    border: 1px solid var(--dark-blue-3);
    object-fit: cover;
    border-radius: .6em;
    width: 3.75em;
    height: auto;
  }
  
  .block-cart-item {
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 1em;
    margin-right: 1em;
  }
  
  .cart-item-name {
    color: var(--white);
    letter-spacing: .025em;
    margin-bottom: .2em;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.3em;
  }
  
  .cart-item-price {
    letter-spacing: .025em;
    margin-bottom: .6em;
    font-size: .9em;
    font-weight: 400;
    line-height: 1.3em;
  }
  
  .text-remove {
    letter-spacing: .13em;
    text-transform: uppercase;
    font-size: .63em;
    font-weight: 500;
    line-height: 1.3em;
  }
  
  .remove-button {
    color: var(--blue-2);
    padding-top: .3em;
    padding-bottom: .3em;
    text-decoration: none;
    transition: color .2s cubic-bezier(.25, .46, .45, .94);
  }
  
  .remove-button:hover {
    color: var(--white);
  }
  
  .option-list {
    margin-top: 0;
    margin-bottom: .6em;
    display: inline-block;
  }
  
  .option {
    background-color: var(--dark-blue-2);
    letter-spacing: .13em;
    text-transform: uppercase;
    border-radius: .4em;
    padding: .7em 1em;
    font-size: .63em;
    font-weight: 500;
    line-height: 1.3em;
  }
  
  .cart-quantity {
    border: 1px solid var(--dark-blue-3);
    color: var(--white);
    letter-spacing: .025em;
    background-color: #050b1180;
    border-radius: .4em;
    width: 3.6em;
    height: 2.4em;
    margin-bottom: .8em;
    padding: .5em .6em .5em .8em;
    font-size: .9em;
    line-height: 1.3em;
    transition: border-color .2s cubic-bezier(.25, .46, .45, .94);
  }
  
  .cart-quantity:focus {
    border-color: var(--blue-2);
  }
  
  .cart-line-item {
    letter-spacing: .025em;
    margin-bottom: 1em;
    font-size: .9em;
  }
  
  .text-subtotal {
    color: var(--white);
    font-size: 1.2em;
    font-weight: 500;
  }
  
  .checkout-button {
    background-color: #0000;
    background-image: linear-gradient(20deg, var(--blue-1), var(--blue-3));
    box-shadow: 0 7px 80px -12px var(--blue-2), inset 0 0 0 0 #13243600, inset 0 0 0 0 #13243600;
    color: var(--white);
    text-align: center;
    letter-spacing: .025em;
    border-radius: .62em;
    margin-top: 1.2em;
    padding: 1em 1.8em;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.2em;
    transition: box-shadow .4s cubic-bezier(.25, .46, .45, .94);
  }
  
  .checkout-button:hover {
    box-shadow: 0 0 0 -10px var(--blue-2), inset -15px 13px 30px 0 var(--dark-blue-3), inset 20px -55px 30px 0 var(--dark-blue-3);
  }
  
  .apple-pay, .pay-with-browser {
    border-radius: 50em;
    height: 2.7em;
  }
  
  .cart-empty-state {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4.4em;
    padding-bottom: 4.4em;
  }
  
  .text-cart-empty {
    margin-bottom: 1.4em;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.3em;
  }
  
  .cart-error-state {
    background-color: #0000;
    border: 1px solid #e74c5f;
    border-radius: .62em;
    margin: 0 1.5em 1.5em;
    padding: .8em 1.2em;
    font-size: .9em;
    line-height: 1.3em;
  }
  
  .text-cart {
    color: var(--white);
    letter-spacing: .025em;
    font-size: .94em;
    font-weight: 500;
    line-height: 1.2em;
    display: none;
  }
  
  .cart-button {
    grid-column-gap: .25em;
    grid-row-gap: .25em;
    background-color: var(--dark-blue-2);
    border-radius: 50em;
    justify-content: flex-start;
    align-items: center;
    padding: .6em 1.2em;
    transition: box-shadow .4s cubic-bezier(.25, .46, .45, .94);
    position: relative;
  }
  
  .cart-button:hover {
    box-shadow: 0 5px 40px -10px var(--blue-2), inset -15px 13px 20px 0 var(--blue-3), inset 20px -55px 20px 0 var(--blue-1);
  }
  
  .icon-cart {
    flex: none;
    width: 1.2em;
    height: 1.2em;
  }
  
  .icon-cart-quantity {
    background-color: var(--blue-1);
    color: var(--white);
    border-radius: 50em;
    min-width: auto;
    height: auto;
    margin-left: 0;
    padding: .4em .7em .3em;
    font-size: .72em;
    font-weight: 500;
    line-height: 1.1em;
    position: absolute;
    inset: -16% -16% auto auto;
  }
  
  .categories {
    border: 1px solid var(--dark-blue-3);
    border-radius: 1.2em;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 3.1em;
    padding: .7em;
    display: flex;
    position: relative;
  }
  
  .subtitle-categories {
    background-color: var(--dark-blue-0);
    color: var(--paragraphs);
    text-align: center;
    letter-spacing: .13em;
    text-transform: uppercase;
    border-radius: .6em;
    padding-left: 1em;
    padding-right: 1em;
    font-size: .63em;
    font-weight: 500;
    line-height: 1.2em;
    position: absolute;
    inset: -.7em auto auto;
  }
  
  .block-categories {
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    display: flex;
  }
  
  .link-category {
    color: var(--paragraphs);
    letter-spacing: .025em;
    background-color: #0000;
    border-radius: .8em;
    padding: 1em 1.4em;
    font-size: .94em;
    font-weight: 500;
    line-height: 1.2em;
    text-decoration: none;
    transition: color .2s cubic-bezier(.25, .46, .45, .94);
    display: block;
  }
  
  .link-category:hover {
    color: var(--white);
  }
  
  .link-category.w--current {
    background-color: var(--dark-blue-2);
  }
  
  .collection-list-categories {
    justify-content: flex-start;
    align-items: center;
  }
  
  .collection-item-categories {
    display: inline-block;
  }
  
  .collection-list-shop {
    grid-column-gap: 1.8em;
    grid-row-gap: 1.8em;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    align-items: start;
    display: grid;
  }
  
  .collection-list-shop.small {
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  .collection-item-shop {
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    display: flex;
  }
  
  .block-product {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3.7em;
    display: flex;
  }
  
  .block-product.small {
    padding: 2.2em;
  }
  
  .link-product-thumbnail {
    border: 1px solid #0000;
    border-radius: 1em;
    width: 100%;
    margin-bottom: 2.6em;
    transition: border-color .4s cubic-bezier(.25, .46, .45, .94);
  }
  
  .link-product-thumbnail:hover {
    border-color: var(--blue-2);
  }
  
  .link-product-thumbnail.small {
    margin-bottom: 1.4em;
  }
  
  .image-product-thumbnail {
    object-fit: cover;
    border-radius: 1em;
    width: 100%;
  }
  
  .link-product {
    margin-bottom: .6em;
    text-decoration: none;
  }
  
  .heading-product.main {
    margin-bottom: .35em;
  }
  
  .block-price {
    line-height: 1.2em;
  }
  
  .block-price.small {
    font-size: .9em;
  }
  
  .price {
    color: var(--blue-2);
    font-size: 1em;
    font-weight: 500;
    line-height: 1.2em;
    display: inline-block;
  }
  
  .compare-at-price {
    color: var(--paragraphs-dark);
    margin-left: .8em;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.2em;
    text-decoration: line-through;
    display: inline-block;
  }
  
  .heading-similar-products {
    margin-bottom: 1.1em;
    display: inline-block;
  }
  
  .section-product {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8.8em 1.8em 0;
    display: flex;
    position: relative;
  }
  
  .grid-product {
    grid-column-gap: 1.8em;
    grid-row-gap: 1.8em;
    grid-template-rows: auto;
    grid-template-columns: 1.5fr 1fr;
    align-items: start;
  }
  
  .image-product {
    border: 1px solid var(--dark-blue-3);
    object-fit: cover;
    border-radius: 1.2em;
    width: 100%;
  }
  
  .block-image-product, .collection-list-more-img {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    display: flex;
  }
  
  .collection-item-more-img {
    margin-top: 1.8em;
  }
  
  .content-product {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3.3em 3.7em 3.7em;
    display: flex;
  }
  
  .border-product {
    background-color: var(--paragraphs);
    opacity: .15;
    width: 100%;
    height: 1px;
    margin-top: 2.5em;
    margin-bottom: 2.5em;
  }
  
  .paragraph-description {
    margin-top: 2.3em;
  }
  
  .expanded-description {
    margin-bottom: -1.7em;
  }
  
  .add-to-cart {
    width: 100%;
  }
  
  .quantity {
    border: 1px solid var(--dark-blue-3);
    color: var(--white);
    letter-spacing: .025em;
    background-color: #050b1180;
    border-radius: .62em;
    width: 5em;
    height: 3.7em;
    margin-bottom: .8em;
    padding: .9em .6em .9em 1.2em;
    font-size: 1em;
    line-height: 1.3em;
    transition: border-color .2s cubic-bezier(.25, .46, .45, .94);
  }
  
  .quantity:focus {
    border-color: var(--blue-2);
  }
  
  .default-state {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 0;
    display: flex;
  }
  
  .buy-now-button {
    background-color: #0000;
    background-image: linear-gradient(20deg, var(--blue-1), var(--blue-3));
    box-shadow: 0 7px 80px -12px var(--blue-2), inset 0 0 0 0 #13243600, inset 0 0 0 0 #13243600;
    color: var(--white);
    text-align: center;
    letter-spacing: .025em;
    border-radius: .62em;
    margin-top: 1.2em;
    padding: 1em 1.8em;
    font-size: 1.12em;
    font-weight: 500;
    line-height: 1.2em;
    transition: box-shadow .4s cubic-bezier(.25, .46, .45, .94);
    display: block;
  }
  
  .buy-now-button:hover {
    box-shadow: 0 0 0 -10px var(--blue-2), inset -15px 13px 30px 0 var(--dark-blue-3), inset 20px -55px 30px 0 var(--dark-blue-3);
  }
  
  .add-to-cart-button {
    background-color: #0000;
    background-image: linear-gradient(20deg, var(--blue-1), var(--blue-3));
    box-shadow: 0 7px 80px -12px var(--blue-2), inset 0 0 0 0 #13243600, inset 0 0 0 0 #13243600;
    color: var(--white);
    text-align: center;
    letter-spacing: .025em;
    border-radius: .62em;
    margin-top: 1.2em;
    padding: 1em 1.8em;
    font-size: 1.12em;
    font-weight: 500;
    line-height: 1.2em;
    transition: box-shadow .4s cubic-bezier(.25, .46, .45, .94);
    display: block;
  }
  
  .add-to-cart-button:hover {
    box-shadow: 0 0 0 -10px var(--blue-2), inset -15px 13px 30px 0 var(--dark-blue-3), inset 20px -55px 30px 0 var(--dark-blue-3);
  }
  
  .select-field {
    border: 1px solid var(--dark-blue-3);
    color: var(--white);
    letter-spacing: .025em;
    background-color: #050b1180;
    border-radius: .62em;
    height: 3.7em;
    margin-bottom: 1.6em;
    padding: .8em 1.12em;
    font-size: 1em;
    line-height: 1.3em;
    transition: border-color .2s cubic-bezier(.25, .46, .45, .94);
  }
  
  .select-field:focus {
    border-color: var(--blue-2);
  }
  
  .button-group {
    margin-bottom: 1.6em;
  }
  
  .button-option {
    border: 1px solid var(--dark-blue-3);
    color: var(--paragraphs);
    background-color: #050b1180;
    border-radius: .62em;
    margin-right: .45em;
    padding: 1.1em 1.2em;
    font-size: 1em;
    line-height: 1.3em;
    transition: background-color .2s cubic-bezier(.25, .46, .45, .94), border-color .2s cubic-bezier(.25, .46, .45, .94), color .2s cubic-bezier(.25, .46, .45, .94);
  }
  
  .button-option.w--ecommerce-pill-selected {
    border-color: var(--blue-2);
    background-color: var(--dark-blue-0);
    color: var(--white);
  }
  
  .out-of-stock-state {
    background-color: #0000;
    border: 1px solid #e74c5f;
    border-radius: .62em;
    margin-top: 0;
    padding: 1em 1.2em;
  }
  
  .text-out-of-stock {
    color: #e74c5f;
    letter-spacing: .025em;
    font-weight: 500;
  }
  
  .ecommerce-form {
    background-color: #0000;
    min-height: auto;
    padding: 0;
  }
  
  .ecommerce-grid {
    grid-column-gap: 1.8em;
    grid-row-gap: 1.8em;
    grid-template-rows: auto;
    grid-template-columns: 2fr 1fr;
    align-items: start;
    width: 100%;
  }
  
  .ecommerce-contents {
    margin-right: 0;
  }
  
  .ecommerce-sidebar {
    top: 6em;
  }
  
  .customer-info, .shipping-address, .shipping-method, .payment-info {
    border: 1px solid var(--dark-blue-3);
    background-color: var(--dark-blue-1);
    border-radius: 1.2em;
    margin-bottom: 1.8em;
  }
  
  .billing-address {
    border: 1px solid var(--dark-blue-3);
    background-color: var(--dark-blue-1);
    border-radius: 1.2em;
    margin-top: 0;
    margin-bottom: 1.8em;
  }
  
  .order-items {
    border: 1px solid var(--dark-blue-3);
    background-color: var(--dark-blue-1);
    border-radius: 1.2em;
    margin-bottom: 1.8em;
  }
  
  .block-header {
    border-style: none none solid;
    border-color: var(--dark-blue-3);
    background-color: #0000;
    border-bottom-width: 1px;
    padding: 1.2em 1.5em;
  }
  
  .block-content {
    background-color: #0000;
    border: 1px #000;
    padding: 1.5em;
  }
  
  .text-field-ecommerce {
    border: 1px solid var(--dark-blue-3);
    color: var(--white);
    letter-spacing: .025em;
    background-color: #050b1180;
    border-radius: .62em;
    height: 3.75em;
    margin-bottom: 1.5em;
    padding: .8em 1.12em;
    font-size: 1em;
    line-height: 1.3em;
    transition: border-color .3s cubic-bezier(.25, .46, .45, .94);
  }
  
  .text-field-ecommerce:focus, .text-field-ecommerce.-wfp-focus {
    border-color: var(--blue-2);
  }
  
  .text-field-ecommerce::placeholder {
    color: var(--paragraphs-dark);
  }
  
  .text-field-ecommerce.margin-0 {
    margin-bottom: 0;
  }
  
  .text-field-ecommerce.margin-small {
    margin-bottom: .5em;
  }
  
  .select-field-ecommerce {
    border: 1px solid var(--dark-blue-3);
    color: var(--white);
    letter-spacing: .025em;
    background-color: #050b1180;
    border-radius: .62em;
    height: 3.7em;
    margin-bottom: 0;
    padding: .8em 1.12em;
    font-size: 1em;
    line-height: 1.3em;
    transition: border-color .2s cubic-bezier(.25, .46, .45, .94);
  }
  
  .select-field-ecommerce:focus {
    border-color: var(--blue-2);
  }
  
  .order-summary {
    border: 1px solid var(--dark-blue-3);
    background-color: var(--dark-blue-1);
    border-radius: 1.2em;
    margin-bottom: 1.8em;
  }
  
  .text-required {
    color: var(--blue-2);
    letter-spacing: .025em;
    font-size: .9em;
    font-weight: 500;
    line-height: 1.2em;
  }
  
  .block-quantity {
    margin-top: .4em;
    margin-bottom: .4em;
    font-size: .9em;
    line-height: 1.3em;
  }
  
  .order-item {
    align-items: flex-start;
    margin-bottom: 1.7em;
  }
  
  .order-item-list {
    margin-bottom: -1.7em;
  }
  
  .billing-address-toggle {
    letter-spacing: .025em;
    justify-content: flex-start;
    align-items: baseline;
    margin-top: 1em;
    line-height: 1.3em;
    display: flex;
  }
  
  .shipping-method-ecommerce {
    background-color: var(--dark-blue-2);
    border: 1px #000;
    border-radius: .62em;
    margin-bottom: .7em;
    padding: 1em;
  }
  
  .shipping-method-list {
    border: 1px #000;
  }
  
  .name-shipping {
    color: var(--white);
    letter-spacing: .025em;
    margin-bottom: .2em;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.3em;
  }
  
  .price-shipping {
    letter-spacing: .025em;
    margin-bottom: .6em;
    font-size: .9em;
    font-weight: 400;
    line-height: 1.3em;
  }
  
  .discounts {
    border-style: solid none none;
    border-color: var(--dark-blue-3);
    background-color: #0000;
    padding: 1.5em;
  }
  
  .apply-button {
    background-color: #0000;
    background-image: linear-gradient(20deg, var(--blue-1), var(--blue-3));
    box-shadow: 0 7px 80px -12px var(--blue-2), inset 0 0 0 0 #13243600, inset 0 0 0 0 #13243600;
    color: var(--white);
    text-align: center;
    letter-spacing: .025em;
    border-radius: .62em;
    height: auto;
    margin-top: 0;
    padding: 1em 1.2em;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.2em;
    transition: box-shadow .4s cubic-bezier(.25, .46, .45, .94);
  }
  
  .apply-button:hover {
    box-shadow: 0 0 0 -10px var(--blue-2), inset -15px 13px 30px 0 var(--dark-blue-3), inset 20px -55px 30px 0 var(--dark-blue-3);
  }
  
  .heading-template {
    text-align: center;
    margin-bottom: .8em;
  }
  
  .grid-button {
    grid-column-gap: 1.2em;
    grid-row-gap: 1.2em;
    grid-template-rows: auto;
    grid-template-columns: auto;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    justify-content: start;
    place-items: center;
    margin-top: 2em;
  }
  
  .paragraph-template {
    text-align: center;
    margin-bottom: 2.2em;
  }
  
  .grid-licensing {
    grid-column-gap: .8em;
    grid-row-gap: .8em;
    grid-template-rows: auto;
  }
  
  .image-template {
    border: 1px solid var(--dark-blue-3);
    object-fit: cover;
    border-radius: .8em;
    width: 100%;
  }
  
  .grid-icon-licensing {
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    grid-template-rows: auto;
    grid-template-columns: auto auto auto auto auto auto;
    grid-auto-columns: auto;
    justify-content: start;
    place-items: center;
  }
  
  .icon-licensing {
    border: 1px solid var(--dark-blue-3);
    background-color: var(--dark-blue-1);
    border-radius: 1.2em;
    padding: 1em;
  }
  
  .font-template {
    border: 1px solid var(--dark-blue-3);
    background-color: var(--dark-blue-1);
    border-radius: 1.2em;
    padding: 25px;
  }
  
  .creato-display {
    color: var(--paragraphs-dark);
    -webkit-text-fill-color: inherit;
    background-image: none;
    background-clip: border-box;
  }
  
  .grid-hero {
    grid-column-gap: 0em;
    grid-row-gap: 0em;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    padding-left: .5em;
    padding-right: .5em;
  }
  
  .overflow {
    flex-flow: column;
    flex: 1;
    display: flex;
    overflow: hidden;
  }
  
  .blur-top {
    z-index: -10;
    background-color: var(--blur-blue);
    filter: blur(150px);
    border-radius: 100%;
    width: 75%;
    height: 22em;
    position: absolute;
    inset: -12em auto auto -25%;
    transform: perspective(1000px);
  }
  
  .wrapper-circle {
    justify-content: center;
    align-items: center;
    min-height: 26em;
    display: flex;
    position: relative;
  }
  
  .area-main-a6 {
    z-index: 5;
    border-radius: 100%;
    width: 30em;
    height: 30em;
    position: relative;
  }
  
  .circle-hero {
    transform-style: preserve-3d;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    display: flex;
    position: relative;
    top: 1.4em;
    transform: rotateX(60deg)rotateY(26deg)rotateZ(0);
  }
  
  .content-wrapper-circle {
    perspective: 2000px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    inset: 0%;
    transform: perspective(2000px);
  }
  
  .area-a5 {
    z-index: 6;
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate3d(0, 0, 3em);
  }
  
  .area-a4 {
    z-index: 7;
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 93%;
    height: 93%;
    position: absolute;
    transform: translate3d(0, 0, 6em);
  }
  
  .area-a3 {
    z-index: 8;
    filter: blur(10px);
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 82%;
    height: 82%;
    position: absolute;
    transform: translate3d(0, 0, 9em);
  }
  
  .area-a2 {
    z-index: 9;
    filter: blur(15px);
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 64%;
    height: 64%;
    position: absolute;
    transform: translate3d(0, 0, 12em);
  }
  
  .area-a1 {
    z-index: 10;
    filter: blur(20px);
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 38%;
    height: 38%;
    position: absolute;
    transform: translate3d(0, 0, 15em);
  }
  
  .area-a7 {
    z-index: 4;
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 93%;
    height: 93%;
    position: absolute;
    transform: translate3d(0, 0, -3em);
  }
  
  .area-a8 {
    z-index: 3;
    filter: blur(10px);
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 82%;
    height: 82%;
    position: absolute;
    transform: translate3d(0, 0, -6em);
  }
  
  .area-a9 {
    z-index: 2;
    filter: blur(15px);
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 64%;
    height: 64%;
    position: absolute;
    transform: translate3d(0, 0, -9em);
  }
  
  .area-a10 {
    z-index: 1;
    filter: blur(20px);
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 38%;
    height: 38%;
    position: absolute;
    transform: translate3d(0, 0, -12em);
  }
  
  .blur-hero {
    z-index: -10;
    background-color: var(--blur-blue);
    filter: blur(150px);
    border-radius: 100%;
    width: 50em;
    height: 20em;
    position: absolute;
    bottom: -4em;
    transform: perspective(1000px);
  }
  
  .color-circle {
    background-image: linear-gradient(72deg, var(--circle-1), var(--circle-2) 80%);
    box-shadow: inset 0 0 0 1px var(--circle-border);
    border-radius: 100%;
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .color-circle.line {
    box-shadow: inset 0 0 0 3px var(--circle-accent);
    background-image: none;
  }
  
  .block-feature {
    grid-column-gap: 1.7em;
    grid-row-gap: 1.7em;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 2.5em;
    padding-left: 2.5em;
    padding-right: 2.5em;
    display: flex;
  }
  
  .block-feature.top {
    padding-top: 2.5em;
    padding-bottom: 0;
  }
  
  .grid-features-small {
    grid-column-gap: 1.25em;
    grid-row-gap: 1.25em;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: start stretch;
    margin-top: 1.25em;
  }
  
  .block-cta {
    grid-column-gap: 2.4em;
    grid-row-gap: 2.4em;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    position: relative;
  }
  
  .blur-block {
    z-index: -10;
    background-color: var(--blur-blue);
    filter: blur(150px);
    border-radius: 100%;
    width: 50em;
    height: 24em;
    position: absolute;
    bottom: -4em;
    left: -15em;
    transform: rotate(-20deg)perspective(1000px);
  }
  
  .circle-b {
    transform-style: preserve-3d;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    display: flex;
    position: relative;
    top: -3em;
    transform: rotateX(37deg)rotateY(53deg)rotateZ(0);
  }
  
  .area-main-b3 {
    z-index: 5;
    border-radius: 100%;
    width: 30em;
    height: 30em;
    position: relative;
  }
  
  .area-b2 {
    z-index: 6;
    filter: blur(7px);
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 80%;
    height: 80%;
    position: absolute;
    transform: translate3d(3.5em, 5em, 3.5em);
  }
  
  .area-b1 {
    z-index: 7;
    filter: blur(20px);
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 50%;
    height: 50%;
    position: absolute;
    transform: translate3d(10.7em, 9.8em, 7em);
  }
  
  .area-b4 {
    z-index: 4;
    filter: blur(7px);
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 93%;
    height: 93%;
    position: absolute;
    transform: translate3d(1.4em, 6.5em, -4.4em);
  }
  
  .area-b5 {
    z-index: 3;
    filter: blur(20px);
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 60%;
    height: 60%;
    position: absolute;
    transform: translate3d(-4.7em, 6.2em, -11em);
  }
  
  .blur-block-b {
    z-index: -10;
    background-color: var(--blur-blue);
    filter: blur(150px);
    border-radius: 100%;
    width: 50em;
    height: 24em;
    position: absolute;
    bottom: 1.6em;
    transform: rotate(-26deg)perspective(1000px);
  }
  
  .circle-c {
    transform-style: preserve-3d;
    justify-content: center;
    align-items: center;
    font-size: 1.1em;
    display: flex;
    position: relative;
    top: -1em;
    transform: rotateX(64deg)rotateY(-28deg)rotateZ(0);
  }
  
  .area-c1 {
    z-index: 7;
    filter: blur(20px);
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 40%;
    height: 40%;
    position: absolute;
    transform: translate3d(0, 0, 8.9em);
  }
  
  .area-c2 {
    z-index: 6;
    filter: blur(7px);
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 77%;
    height: 77%;
    position: absolute;
    transform: translate3d(0, 0, 4.7em);
  }
  
  .area-main-c3 {
    z-index: 5;
    border-radius: 100%;
    width: 30em;
    height: 30em;
    position: relative;
  }
  
  .area-c4 {
    z-index: 4;
    filter: blur(7px);
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 110%;
    height: 110%;
    position: absolute;
    transform: translate3d(0, 0, -4.7em);
  }
  
  .area-c5 {
    z-index: 3;
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 77%;
    height: 77%;
    position: absolute;
    transform: translate3d(0, 0, -10.7em);
  }
  
  .area-c6 {
    z-index: 2;
    filter: blur(20px);
    transform-style: preserve-3d;
    border-radius: 100%;
    width: 50%;
    height: 50%;
    position: absolute;
    transform: translate3d(0, 0, -16.4em);
  }
  
  .blur-block-c {
    z-index: -10;
    background-color: var(--blur-blue);
    filter: blur(150px);
    border-radius: 100%;
    width: 50em;
    height: 24em;
    position: absolute;
    bottom: 2.4em;
    transform: rotate(-26deg)perspective(1000px);
  }
  
  .image {
    max-width: 16%;
    font-size: 16px;
  }
  
  .heading-2 {
    position: relative;
    top: -128px;
    left: -235px;
  }
  
  .image-2 {
    max-width: 6%;
  }
  
  @media screen and (max-width: 991px) {
    h1 {
      font-size: 3.8em;
    }
  
    h2 {
      font-size: 2.8em;
    }
  
    h3 {
      font-size: 2em;
    }
  
    h4 {
      font-size: 1.5em;
    }
  
    h5 {
      font-size: 1.2em;
    }
  
    h6 {
      font-size: 1em;
    }
  
    .nav-menu {
      border: 1px solid var(--dark-blue-3);
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      background-color: #050d15cc;
      border-radius: 1.2em;
      margin-top: .7em;
      padding: .7em;
      position: absolute;
      left: 2.2em;
      right: 2.2em;
    }
  
    .nav-link {
      text-align: left;
      padding-top: .9em;
      padding-bottom: .9em;
    }
  
    .menu-button {
      color: var(--paragraphs);
      border-radius: 50em;
      padding: .4em .7em;
      font-size: 1.35em;
      line-height: 1em;
      transition: box-shadow .4s cubic-bezier(.25, .46, .45, .94), color .2s cubic-bezier(.25, .46, .45, .94);
    }
  
    .menu-button.w--open {
      box-shadow: 0 5px 40px -10px var(--blue-2), inset -15px 13px 20px 0 var(--blue-3), inset 20px -55px 20px 0 var(--blue-1);
      color: var(--white);
      background-color: #0000;
    }
  
    .section-hero {
      padding-top: 10em;
      padding-bottom: 6em;
    }
  
    .heading-hero.large {
      font-size: 4.2em;
    }
  
    .section {
      padding-top: 4.5em;
      padding-bottom: 4.5em;
    }
  
    .section.post {
      padding-top: 1.5em;
      padding-bottom: 3em;
    }
  
    .grid-features {
      grid-column-gap: .9em;
      grid-row-gap: .9em;
    }
  
    .block-heading {
      margin-bottom: 2.5em;
    }
  
    .section-clients {
      padding-bottom: 4.5em;
    }
  
    .logo-client {
      max-height: 2em;
      margin-left: 2.2em;
      margin-right: 2.2em;
    }
  
    .grid {
      grid-column-gap: 1.5em;
      grid-row-gap: 1.5em;
    }
  
    .block {
      padding-top: 2em;
      padding-bottom: 2em;
    }
  
    .grid-plan {
      grid-column-gap: .9em;
      grid-row-gap: .9em;
    }
  
    .plan {
      padding: 2.5em 2.7em 2.7em;
    }
  
    .grid-benefits {
      grid-column-gap: .9em;
      grid-row-gap: .9em;
      margin-top: .9em;
    }
  
    .grid-reviews {
      grid-column-gap: .9em;
      grid-row-gap: .9em;
    }
  
    .block-reviews {
      grid-row-gap: .9em;
    }
  
    .review {
      padding: 1.8em 2.2em;
    }
  
    .section-footer {
      padding-top: 4.5em;
    }
  
    .grid-footer {
      margin-bottom: 7.5em;
    }
  
    .block-footer-down {
      margin-bottom: 2.7em;
    }
  
    .section-top {
      padding-top: 10em;
      padding-bottom: 2em;
    }
  
    .block-top {
      grid-row-gap: 2em;
    }
  
    .paragraph-block {
      margin-top: 1.2em;
    }
  
    .collection-list {
      grid-column-gap: 1.2em;
      grid-row-gap: 1.2em;
    }
  
    .block-post {
      padding: 2.7em;
    }
  
    .image-post-thumbnail {
      height: 23vw;
    }
  
    .image-main-post {
      margin-bottom: 3.2em;
    }
  
    .contact, .account-form {
      padding: 2.7em;
    }
  
    .categories {
      margin-bottom: 2em;
    }
  
    .collection-list-shop {
      grid-column-gap: 1.2em;
      grid-row-gap: 1.2em;
    }
  
    .block-product {
      padding: 2.7em;
    }
  
    .link-product-thumbnail {
      margin-bottom: 2em;
    }
  
    .section-product {
      padding-top: 7.8em;
    }
  
    .grid-product {
      grid-column-gap: 1.2em;
      grid-row-gap: 1.2em;
    }
  
    .content-product {
      padding: 2.5em 2.7em 2.7em;
    }
  
    .ecommerce-grid {
      grid-column-gap: 1.2em;
      grid-row-gap: 1.2em;
    }
  
    .circle-hero {
      font-size: 1em;
    }
  
    .grid-features-small {
      grid-column-gap: .9em;
      grid-row-gap: .9em;
      margin-top: .9em;
    }
  
    .circle-b {
      font-size: .8em;
    }
  
    .circle-c {
      font-size: .9em;
    }
  }
  
  @media screen and (max-width: 767px) {
    body {
      font-size: 15px;
    }
  
    h1 {
      font-size: 3.4em;
    }
  
    h2 {
      font-size: 2.5em;
    }
  
    h3 {
      font-size: 1.9em;
    }
  
    .navbar {
      padding-left: 1.8em;
      padding-right: 1.8em;
    }
  
    .nav-menu {
      left: 1.8em;
      right: 1.8em;
    }
  
    .block-hero {
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  
    .heading-hero.large {
      font-size: 3.7em;
    }
  
    .grid-features, .grid {
      grid-template-columns: 1fr;
    }
  
    .block.right {
      padding-left: 0%;
    }
  
    .grid-tools {
      grid-template-columns: 1fr 1fr;
    }
  
    .grid-plan, .grid-benefits, .grid-reviews {
      grid-template-columns: 1fr;
    }
  
    .grid-footer {
      grid-row-gap: 5em;
      grid-template-columns: 1fr 1fr 1fr;
    }
  
    .collection-list {
      grid-template-columns: 1fr;
    }
  
    .image-post-thumbnail {
      height: 47vw;
    }
  
    .grid-account {
      grid-template-columns: 1fr;
    }
  
    .sing-up-banner {
      display: none;
    }
  
    .collection-list-shop, .collection-list-shop.small, .grid-product, .ecommerce-grid {
      grid-template-columns: 1fr;
    }
  
    .grid-icon-licensing {
      grid-template-columns: auto auto auto auto;
    }
  
    .grid-hero {
      grid-template-columns: 1fr;
    }
  
    .blur-top {
      width: 120%;
      height: 26em;
      left: -60%;
    }
  
    .blur-hero {
      width: 38em;
      height: 18em;
    }
  
    .grid-features-small {
      grid-template-columns: 1fr 1fr;
    }
  
    .blur-block {
      width: 40em;
      height: 20em;
    }
  
    .blur-block-b {
      width: 40em;
      height: 18em;
    }
  
    .blur-block-c {
      width: 42em;
      height: 20em;
    }
  }
  
  @media screen and (max-width: 479px) {
    h1 {
      font-size: 2.7em;
    }
  
    h2 {
      font-size: 2.1em;
    }
  
    h3 {
      font-size: 1.8em;
    }
  
    .nav-block {
      -webkit-backdrop-filter: none;
      backdrop-filter: none;
    }
  
    .navbar {
      padding-left: .7em;
      padding-right: .7em;
      position: absolute;
    }
  
    .nav-menu {
      left: .7em;
      right: .7em;
    }
  
    .nav-link-extra, .nav-button {
      display: none;
    }
  
    .section-hero {
      padding-top: 9em;
      padding-bottom: 4em;
    }
  
    .heading-hero.large {
      font-size: 2.6em;
    }
  
    .section {
      padding-top: 3.5em;
      padding-bottom: 3.5em;
    }
  
    .section.post {
      padding-bottom: 2em;
    }
  
    .section-clients {
      padding-bottom: 3.5em;
    }
  
    .logo-client {
      max-height: 1.7em;
      margin-left: 1.8em;
      margin-right: 1.8em;
    }
  
    .block {
      padding-top: 0;
      padding-bottom: 0;
    }
  
    .grid-tools {
      grid-column-gap: .75em;
      grid-row-gap: 2em;
      font-size: 14px;
    }
  
    .plan {
      padding: 2em;
    }
  
    .price-plan {
      font-size: 2.8em;
    }
  
    .review {
      padding-left: 1.6em;
      padding-right: 1.6em;
    }
  
    .section-footer {
      padding-top: 3.5em;
    }
  
    .grid-footer {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 6.5em;
    }
  
    .banner {
      padding: 7em 2.5em;
    }
  
    .image-banner {
      width: 50%;
    }
  
    .section-top {
      padding-top: 9em;
      padding-bottom: 1em;
    }
  
    .block-post {
      padding: 2em;
    }
  
    .image-main-post {
      margin-bottom: 2.5em;
    }
  
    .contact {
      padding: 2em;
    }
  
    .field-group {
      flex-direction: column;
    }
  
    .account-form {
      padding: 2em;
    }
  
    .paragraph-form {
      margin-top: 1em;
    }
  
    .cart-container {
      margin-top: 0;
    }
  
    .block-product, .block-product.small {
      padding: 2em;
    }
  
    .link-product-thumbnail {
      margin-bottom: 1.5em;
    }
  
    .block-price {
      font-size: 14px;
    }
  
    .section-product {
      padding-top: 7em;
    }
  
    .content-product {
      padding: 2em;
    }
  
    .button-group {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .button-option {
      margin-bottom: .45em;
      margin-right: 0;
    }
  
    .block-header {
      grid-row-gap: .3em;
      flex-direction: column;
    }
  
    .grid-button {
      grid-auto-flow: row;
    }
  
    .grid-licensing {
      grid-template-columns: 1fr;
    }
  
    .grid-icon-licensing {
      grid-column-gap: 1.4em;
      grid-row-gap: 1.4em;
      grid-template-columns: auto auto auto;
    }
  
    .wrapper-circle {
      min-height: 22em;
    }
  
    .circle-hero {
      font-size: .7em;
    }
  
    .blur-hero {
      bottom: -2.7em;
    }
  
    .grid-features-small {
      grid-template-columns: 1fr;
    }
  
    .circle-b {
      font-size: .6em;
    }
  
    .circle-c {
      font-size: .7em;
    }
  }
  
  #w-node-e9b78613-13df-23f7-5a88-5cb6ec3fcf07-c53bab0c, #w-node-_55ccc3df-9ebc-e84e-ceda-3c81384483bc-c53bab0c, #w-node-_55ccc3df-9ebc-e84e-ceda-3c81384483c4-c53bab0c, #w-node-_55ccc3df-9ebc-e84e-ceda-3c81384483cc-c53bab0c, #w-node-_55ccc3df-9ebc-e84e-ceda-3c81384483d4-c53bab0c, #w-node-_55ccc3df-9ebc-e84e-ceda-3c81384483dc-c53bab0c, #w-node-_55ccc3df-9ebc-e84e-ceda-3c81384483e4-c53bab0c, #w-node-_91e6edfb-36c0-e5d4-48fb-589312d14fb3-12d14fb0, #w-node-_91e6edfb-36c0-e5d4-48fb-589312d14fbb-12d14fb0, #w-node-_92ed4044-9cfe-904b-e616-3634ff09c860-12d14fb0, #w-node-_5748c9eb-76ed-0e30-f708-a3242efb407f-c53bab0d, #w-node-_91e91119-4cd0-b208-64fb-d7e460d04b22-c53bab13, #w-node-_2bef1ca3-0d60-32bc-baf0-84e610b2f11f-c53bab18, #w-node-_6d440b59-f9a4-cebe-217e-a45db4112e67-c53bab18 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }
  
  @media screen and (max-width: 767px) {
    #w-node-_91e6edfb-36c0-e5d4-48fb-589312d14fb3-12d14fb0 {
      grid-area: span 1 / span 3 / span 1 / span 3;
    }
  }
  
  @media screen and (max-width: 479px) {
    #w-node-_91e6edfb-36c0-e5d4-48fb-589312d14fb3-12d14fb0 {
      grid-area: span 1 / span 2 / span 1 / span 2;
    }
  }
  
  
  @font-face {
    font-family: 'Creato Display';
    src: url('../fonts/CreatoDisplay-BoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Creato Display';
    src: url('../fonts/CreatoDisplay-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Creato Display';
    src: url('../fonts/CreatoDisplay-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Creato Display';
    src: url('../fonts/CreatoDisplay-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Creato Display';
    src: url('../fonts/CreatoDisplay-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Creato Display';
    src: url('../fonts/CreatoDisplay-RegularItalic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }