/* .has-sub {
    display: none;
  }
  
  .has-sub.open {
    display: block;
  } */
  
  /* General styles */
/* Estilo fijo para el sidebar como en DashboardHeader */
.sidebar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 280px;
  height: 100vh;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  z-index: 1000; /* Asegura que esté por encima del contenido */
}

.sidebar-element {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #343a40;
  color: #fff;
}

.sidebar-content-container {
  padding: 20px;
  flex: 1;
  overflow-y: auto; /* Permitir scroll solo si es necesario */
}

  
  .sidebar-logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  
  .sidebar-logo-light,
  .sidebar-logo-dark {
    max-width: 120px;
    height: auto;
  }
  /* Eliminar scroll adicional en sidebar-scroll */


  
  .menu-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .nav-toggle {
    font-size: 18px;
    color: #fff;
  }
  
  /* Sidebar content */
  .sidebar-scroll {
    flex: 1;
    overflow-y: auto;
  }
  
  .sidebar-content-container {
    padding: 16px 0;
  }
  
  .menu-list-sidebar {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* Menu items */
  .menu-item {
    margin-bottom: 4px;
  }
  
  .menu-link {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    color: #495057;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .menu-link:hover {
    background-color: #e9ecef;
  }
  
  .menu-link-active {
    background-color: #e9ecef;
    color: #343a40;
  }
  
  .menu-icon {
    margin-right: 12px;
    font-size: 18px;
    color: #6c757d;
  }
  
  .menu-text {
    flex: 1;
    font-size: 14px;
    color: #495057;
  }
  
  .menu-badge {
    background-color: #28a745;
    color: #fff;
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 12px;
  }
  
  /* Menu headings */
  .menu-heading-dashboard,
  .menu-heading-prebuilt,
  .menu-heading-misc-pages {
    margin: 12px 16px;
    font-size: 12px;
    font-weight: 600;
    color: #6c757d;
    text-transform: uppercase;
  }
  
  /* Submenu styles */
  .menu-wrap {
    height: 0px;
    overflow: hidden;
    transition: height 0.3s ease;
  }
  
  .menu-wrap.open {
    height: 100px;
  }
  
  .menu-sub {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu-sub .menu-item {
    margin-bottom: 4px;
  }
  
  .menu-sub .menu-link {
    padding: 8px 16px;
    font-size: 14px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .sidebar-container {
      width: 100%;
      height: auto;
    }
  
    .sidebar-element {
      padding: 12px;
    }
  
    .menu-link {
      padding: 10px 12px;
    }
  
    .menu-text {
      font-size: 13px;
    }
  }
  .menu-arrow {
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
  }
  
  .menu-arrow.open {
    transform: rotate(90deg); /* Rota la flecha 45 grados cuando está abierto */
  }
  
  .admin-content {
    display: flex;
  flex-direction: row; /* Coloca los elementos en fila */
  height: 100vh;
  }