
  
  h2, h3 {
    color: #333;
  }
  
  .container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .balance {
    font-size: 1.5rem;
    color: #4caf50;
    margin-bottom: 20px;
  }
  
  .section {
    margin-bottom: 30px;
  }
  
  .section h3 {
    margin-bottom: 10px;
  }
  
  .select-container {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .select-container select, 
  .input-container input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  button {
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
    font-size: 0.9rem;
    margin-top: 10px;
  }
  