.deposit-info-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #1e1e2f; /* Fondo oscuro */
    border: 1px solid #333; /* Borde sutil */
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
    font-family: Arial, sans-serif;
    color: #ffffff; /* Texto claro */
  }
  
  .deposit-info-title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: #4caf50; /* Verde destacado */
    margin-bottom: 15px;
  }
  
  .deposit-info-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .deposit-info-details p {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .deposit-info-details strong {
    color: #bbbbbb; /* Gris claro para etiquetas */
  }
  
  .deposit-info-copy-button {
    margin-top: 10px;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    background-color: #007bff; /* Botón azul */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .deposit-info-copy-button:hover {
    background-color: #0056b3; /* Azul más oscuro al pasar el mouse */
  }
  
  .deposit-info-copy-message {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
    color: #4caf50; /* Verde para éxito */
  }
  