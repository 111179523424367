.custom-tab-menu {
    display: flex;
    gap: 15px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
    justify-content: flex-end; /* Mueve las pestañas a la derecha */
}

.custom-tab-link {
    text-decoration: none;
    padding: 10px 20px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    border-bottom: 2px solid transparent; /* Evita movimientos al cambiar de pestaña */
}

.active-tab {
    border-bottom: 2px solid white; /* Solo el seleccionado tiene línea blanca */
}


.transaction-convert {
    display: flex;
    flex-direction: column;
    gap: 3px;
    text-align: center;
  }
  
  .transaction-deposit,
  .transaction-withdrawal {
    text-align: center;
  }
  
  .amount-in {
    color: green;
    font-weight: bold;
  }
  
  .amount-out {
    color: red;
    font-weight: bold;
  }
  .status-wrapper {
    text-align: center;
    font-weight: bold;
  }
  
  .status-complete {
    color: green;
  }
  .status-completed {
    color: green;
  }
  
  .status-pending {
    color: orange;
  }
  
  .status-rejected {
    color: red;
  }

  .load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    margin: 20px auto; /* Centrar */
    cursor: pointer;
    font-weight: bold; /* Texto más grueso */
    text-align: center;
    position: relative;
    color: rgb(255, 255, 255); /* Color del texto */
}

.load-more::after {
    content: "";
    position: absolute;
    bottom: -2px; /* Espacio entre texto y línea */
    left: 50%;
    width: 50%; /* Tamaño de la línea */
    height: 2px; /* Grosor de la línea */
    background-color: white; /* Color de la línea */
    transform: translateX(-50%); /* Centrar la línea debajo del texto */
}


    