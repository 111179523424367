/* .dashboard-main-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f5f6fa;
  } */
  
  .nk-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border-bottom: 1px solid #e6e6e6;
    padding: 0.75rem 1rem;
  }
  
  .nk-header-wrap {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .nk-menu-trigger {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .header-menu {
    flex-grow: 1;
    margin-left: 1rem;
  }
  
  .header-menu-container {
    display: flex;
    justify-content: space-between;
  }
  
  .menu-main {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .menu-item {
    position: relative;
    margin-right: 1.5rem;
  }
  
  .menu-link {
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .menu-link:hover {
    color: #007bff;
  }
  
  .submenu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: none;
    z-index: 1000;
  }
  
  .menu-item:hover .submenu {
    display: block;
  }
  
  .submenu-item {
    list-style: none;
  }
  
  .submenu-link {
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    font-size: 14px;
    color: #333;
    transition: background-color 0.3s ease;
  }
  
  .submenu-link:hover {
    background-color: #f8f9fa;
  }
  
  .header-tools {
    display: flex;
    align-items: center;
  }
  
  .quick-nav {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }
  
  .notification-dropdown,
  .user-dropdown {
    position: relative;
    margin-left: 1rem;
  }
  
  .dropdown-container {
    position: relative;
  }
  
  .dropdown-toggle-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .icon-status-notification,
  .icon-status-user {
    font-size: 20px;
    color: #333;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: none;
    z-index: 1000;
    width: 300px;
  }
  
  .notification-dropdown:hover .dropdown-menu,
  .user-dropdown:hover .dropdown-menu {
    display: block;
  }
  
  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e6e6e6;
  }
  
  .dropdown-title {
    font-weight: 600;
    font-size: 14px;
    color: #333;
  }
  
  .notification-list {
    max-height: 200px;
    overflow-y: auto;
    padding: 0.5rem 0;
  }
  
  .notification-item {
    display: flex;
    align-items: flex-start;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #e6e6e6;
  }
  
  .notification-icon {
    margin-right: 1rem;
    font-size: 20px;
    color: #ffcc00;
  }
  
  .notification-content {
    flex-grow: 1;
  }
  
  .notification-text {
    font-size: 14px;
    color: #333;
  }
  
  .notification-time {
    font-size: 12px;
    color: #666;
  }
  
  .user-dropdown-menu {
    width: 250px;
  }
  
  .user-card-small {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #e6e6e6;
  }
  
  .user-avatar-small {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #007bff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-right: 1rem;
  }
  
  .user-info-small {
    flex-grow: 1;
  }
  
  .user-name {
    font-size: 14px;
    font-weight: 600;
    color: #333;
  }
  
  .user-email {
    font-size: 12px;
    color: #666;
  }
  
  .user-link-list,
  .user-signout-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .user-link-item {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    text-decoration: none;
    font-size: 14px;
    color: #333;
    transition: background-color 0.3s ease;
  }
  
  .user-link-item:hover {
    background-color: #f8f9fa;
  }
  
  .dropdown-footer {
    padding: 0.75rem 1rem;
    border-top: 1px solid #e6e6e6;
  }
  

  .user-dropdown-menu {
    width: 250px;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: none;
  }
  
  .user-dropdown:hover .user-dropdown-menu {
    display: block;
    transform: translate(0px, 33px);
  }
  
  .dropdown-content-wrapper {
    padding: 1rem;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e6e6e6;
  }
  
  .user-card-small {
    display: flex;
    align-items: center;
  }
  
  .user-avatar-small {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #007bff;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-right: 1rem;
  }
  
  .user-info-small {
    flex-grow: 1;
  }
  
  .user-name {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
  
  .user-email {
    font-size: 12px;
    color: #666;
  }
  
  .user-link-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .user-link-item {
    padding: 0.75rem 1rem;
    text-decoration: none;
    font-size: 14px;
    color: #333;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .user-link-item:hover {
    background-color: #f0f0f0;
  }
  
  .dropdown-footer {
    padding: 0.75rem 1rem;
    border-top: 1px solid #e6e6e6;
  }
  
  .user-signout-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  