/* Container for toggle buttons */
.multiple-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  }
  
  /* Base styling for all buttons */
  .tab-button {
    flex: 1;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    background: none;
    color: #d1d1e0;
    border: 1px solid #444;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .tab-button:hover {
    background-color: #3b3b4e;
    color: #ffffff;
  }
  
  /* Styling for the active button */
  .tab-button.active {
    background-color: #4caf50;
    color: #ffffff;
    border: 1px solid #4caf50;
  }
  
  /* Responsive behavior */
  @media (max-width: 768px) {
    .multiple-toggle {
      flex-wrap: wrap;
      gap: 5px;
    }
  
    .tab-button {
      flex: unset;
      width: 100%;
    }
  }
  
  

/* General container styling */
.profile-container {
    /* max-width: 100vh; */
    margin: 0 auto;
    padding: 20px;
    background-color: #1e1e2f;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    color: #ffffff;
  }
  
  /* Title styling */
  .profile-title {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Profile card */
  .profile-card {
    background-color: #2a2a3c;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
  
  .investor-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .investor-name {
    font-size: 18px;
    font-weight: bold;
    color: #d1d1e0;
  }
  
  .investor-doc-status {
    display: flex;
    align-items: center;
    color: #4caf50;
    font-size: 14px;
  }
  
  .investor-doc-status.success i {
    margin-right: 5px;
  }
  
  /* Contact info */
  .contact-info {
    margin-bottom: 20px;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .contact-label {
    font-weight: bold;
    color: #ffffff;
  }
  
  .contact-input {
    flex: 1;
    margin-left: 10px;
    padding: 5px;
    border: 1px solid #444;
    border-radius: 5px;
    background-color: #2a2a3c;
    color: #ffffff;
    cursor: not-allowed;
  }
  
  .contact-edit {
    margin-left: 10px;
    color: #4caf50;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .tax-condition {
    margin-top: 10px;
  }
  
  .tax-select {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #444;
    background-color: #2a2a3c;
    color: #ffffff;
  }
  
  /* Email section */
  .email-section {
    margin-bottom: 20px;
  }
  
  .email-label {
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 5px;
    display: block;
  }
  
  .email-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2a2a3c;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  }
  
  .email-text {
    color: #d1d1e0;
  }
  
  .email-button {
    background-color: #4caf50;
    color: #ffffff;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .email-button:hover {
    background-color: #388e3c;
  }
  
  /* Personal data */
  .personal-data {
    margin-bottom: 20px;
  }
  
  .data-title {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px;
    display: block;
  }
  
  .data-card {
    background-color: #2a2a3c;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  }
  
  .data-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  .data-item span:first-child {
    font-weight: bold;
    color: #d1d1e0;
  }
  
  /* Delete account link */
  .delete-account {
    text-align: center;
  }
  
  .delete-link {
    color: #e57373;
    font-weight: bold;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid #e57373;
    border-radius: 5px;
  }
  
  .delete-link i {
    margin-left: 5px;
  }
  
  .delete-link:hover {
    background-color: #e57373;
    color: #1e1e2f;
  }
  



  /* limites css */

  .limits-content {
    padding: 20px;
    background-color: #1e1e1e;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  
  .limits-header {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 16px;
  }
  
  .limits-summary {
    display: flex;
    gap: 16px;
    margin-bottom: 20px;
  }
  
  .limit-monthly,
  .limit-annual {
    background-color: #252525;
    padding: 15px;
    border-radius: 8px;
    flex: 1;
  }
  
  .limit-label {
    font-size: 18px;
    color: #bbbbbb;
  }
  
  .limit-values {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  }
  
  .limit-used {
    font-size: 16px;
    color: #ffffff;
  }
  
  .limit-total {
    font-size: 16px;
    color: #bbbbbb;
  }
  
  .limit-progress progress {
    width: 100%;
    height: 8px;
    appearance: none;
    background-color: #333333;
  }
  
  .progress-monthly::-webkit-progress-value {
    background-color: #76c7c0;
  }
  
  .progress-annual::-webkit-progress-value {
    background-color: #ff7b72;
  }
  
  /* Limits Info */
  .limits-info {
    margin-bottom: 20px;
  }
  
  .info-item {
    display: flex;
    align-items: center;
    background-color: #252525;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
  }
  
  .info-icon {
    font-size: 20px;
    color: #76c7c0;
    margin-right: 10px;
  }
  
  .info-text {
    font-size: 16px;
    color: #ffffff;
  }
  
  /* Limits Upgrade */
  .limits-upgrade {
    margin-top: 20px;
  }
  
  .upgrade-header {
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 16px;
  }
  
  .upgrade-levels {
    display: flex;
    gap: 16px;
  }
  
  .level-basic,
  .level-intermediate,
  .level-full {
    flex: 1;
    background-color: #252525;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .level-title {
    font-size: 18px;
    color: #76c7c0;
    margin-bottom: 10px;
  }
  
  .level-description {
    font-size: 14px;
    color: #bbbbbb;
    margin-bottom: 10px;
  }
  
  .level-amount {
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 10px;
  }
  
  .level-monthly,
  .level-annual {
    font-size: 14px;
    color: #76c7c0;
  }
  
  .level-button-basic,
.level-button-intermediate,
.level-button-full {
  background-color: #76c7c0;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.level-button-basic:hover,
.level-button-intermediate:hover,
.level-button-full:hover {
  background-color: #5fa8a0;
}

.level-status {
  font-size: 14px;
  color: #bbbbbb;
  margin-top: 10px;
}


/* Contenedor principal */
.contact-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #1e1e2f; /* Fondo oscuro */
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Sombra */
  }
  
  .contact-label {
    font-size: 14px;
    color: #ffffff; /* Texto completamente blanco */
  }
  
  /* Estilo general del input de react-phone-input-2 */
  .react-tel-input {
    background-color: #2a2a3b; /* Fondo oscuro */
    border: 1px solid #4e90ff; /* Borde azul claro */
    border-radius: 4px;
    color: #ffffff; /* Texto completamente blanco */
  }
  
  .react-tel-input .form-control {
    background-color: #2a2a3b; /* Fondo interno oscuro */
    color: #ffffff; /* Texto completamente blanco */
    font-size: 14px;
    border: none; /* Sin bordes adicionales */
    padding: 10px;
  }
  
  .react-tel-input .form-control:focus {
    outline: none;
    border: 1px solid #4e90ff; /* Borde azul claro al enfocar */
  }
  
  .react-tel-input .flag-dropdown {
    background-color: #2a2a3b; /* Fondo oscuro para el selector de bandera */
    color: #ffffff; /* Texto blanco */
    border: none;
  }
  
  .react-tel-input .flag-dropdown:hover {
    background-color: #4e90ff; /* Fondo azul claro al pasar el cursor */
  }
  
  .react-tel-input .country-list {
    background-color: #2a2a3b; /* Fondo oscuro para la lista de países */
    color: #ffffff; /* Texto blanco */
    border: 1px solid #3e3e4f;
  }
  
  .react-tel-input .country-list .country:hover {
    background-color: #4e90ff; /* Fondo azul claro al pasar por encima */
  }
  
  .react-tel-input .country-list .highlight {
    background-color: #4e90ff; /* Fondo del país seleccionado */
    color: #ffffff; /* Asegura texto blanco */
  }
  
  /* Botón de editar o guardar */
  .contact-edit,
  .contact-save {
    font-size: 14px;
    color: #4e90ff; /* Azul claro */
    cursor: pointer;
    text-decoration: none;
  }
  
  .contact-edit:hover,
  .contact-save:hover {
    text-decoration: underline;
  }
  .react-tel-input {
    background-color: #2a2a3b !important; /* Fondo oscuro */
    border: 1px solid #4e90ff !important; /* Borde azul claro */
    border-radius: 4px;
    color: #ffffff !important; /* Texto blanco */
    font-size: 16px; /* Tamaño de texto más grande */
  }
  
  .react-tel-input .form-control {
    background-color: #2a2a3b !important; /* Fondo oscuro interno */
    color: #ffffff !important; /* Texto blanco */
    font-size: 16px; /* Tamaño de texto */
    border: none;
    padding: 10px;
  }
  
  .react-tel-input .form-control::placeholder {
    color: #bbbbbb; /* Placeholder gris claro */
    opacity: 1; /* Asegura que sea visible */
  }
  
  .react-tel-input .flag-dropdown {
    background-color: #2a2a3b !important; /* Fondo oscuro para bandera */
    color: #ffffff !important; /* Texto blanco */
    border: none;
  }
  
  .react-tel-input .flag-dropdown:hover {
    background-color: #4e90ff !important; /* Hover para bandera */
  }
  
  .react-tel-input .country-list {
    background-color: #2a2a3b !important; /* Fondo oscuro lista de países */
    color: #ffffff !important; /* Texto blanco */
  }
  
  .react-tel-input .country-list .country:hover {
    background-color: #4e90ff !important; /* Hover azul claro */
    color: #ffffff !important;
  }
  
  .react-tel-input .country-list .highlight {
    background-color: #4e90ff !important; /* Fondo seleccionado */
    color: #ffffff !important;
  }
  
  .react-tel-input .selected-flag {
    background-color: #2a2a3b !important;
  }
  
  .react-tel-input .selected-flag .arrow {
    border-top-color: #ffffff !important; /* Flecha en blanco */
  }
  
  .contact-input {
    color: #ffffff !important; /* Texto blanco */
    background-color: transparent !important; /* Fondo transparente */
  }
  