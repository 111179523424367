
h2.unique-title {
    text-align: center;
    color: #ffffff;
    margin: 20px 0;
  }
  
  button.unique-action-button {
    background-color: #1e88e5;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  button.unique-action-button:hover {
    background-color: #1565c0;
  }
  
  .unique-dialog-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .unique-dialog-content {
    background-color: #1e1e1e;
    border-radius: 10px;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    text-align: center;
    color: #ffffff;
  }
  
  .unique-dialog-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .unique-dialog-description {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .unique-qr-code {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .unique-code-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2c2c2c;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .unique-code {
    color: #03dac6;
    font-family: monospace;
  }
  
  button.unique-copy-button {
    background-color: #1e88e5;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  button.unique-copy-button:hover {
    background-color: #1565c0;
  }
  
  .unique-email-code-section,
  .unique-authenticator-code-section {
    margin-bottom: 20px;
  }
  
  .unique-email-code-title,
  .unique-authenticator-code-title {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .unique-email-code-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #2c2c2c;
    border-radius: 5px;
    background-color: #2c2c2c;
    color: #ffffff;
    margin-top: 5px;
  }
  
  .unique-code-inputs {
    display: flex;
    justify-content: space-between;
  }
  
  .unique-code-input {
    width: 40px;
    padding: 10px;
    text-align: center;
    border: 1px solid #2c2c2c;
    border-radius: 5px;
    background-color: #2c2c2c;
    color: #ffffff;
    margin: 5px;
  }
  
  button.unique-verify-button {
    background-color: #03dac6;
    color: #121212;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  button.unique-verify-button:hover {
    background-color: #018786;
  }
  
  .unique-verification-status {
    margin-top: 10px;
    font-size: 14px;
  }
  
  .unique-close-dialog {
    display: flex;
    justify-content: flex-end;
  }
  
  button.unique-dialog-close-button {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 20px;
    cursor: pointer;
  }
  
  button.unique-dialog-close-button:hover {
    color: #03dac6;
  }
  