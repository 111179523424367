/* 📌 Contenedor Principal */
* {
    box-sizing: border-box;
}
.page-wrapper {
    background-color: #121f2d;
    overflow: hidden;
  }
  
  /* 📌 Navbar lateral */
  .side-navbar-wrapper {
    z-index: 9999;
    color: #121f2d;
    background-color: #121f2d;
    position: fixed;
    inset: 0% auto 0% 0%;
    overflow: auto;
    transform: translate(-270px);
    transition: transform 0.3s ease;
  }
  .side-navbar-wrapper.active {
    transform: translateX(0);
  }
  
  .side-navbar-singel-wrapper {
    border-right: 1px solid #32305a;
    flex-direction: column;
    width: 250px;
    min-height: 100vh;
    padding-top: 35px;
    padding-bottom: 40px;
    padding-left: 35px;
    position: relative;
  }
  
  /* 📌 Logo en el Sidebar */
  .sidebar-logo-wrapper {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    padding-right: 15px;
    display: flex;
  }
  
  /* 📌 Menú del Sidebar */
  .sidebar-menu-wrapper {
    padding-bottom: 10px;
  }
  
  .sidebar-menu-link-block {
    color: #a8a5b8;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    display: flex;
  }
  
  .sidebar-menu-link-block.w--current {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(72deg, #14a655, #22a99c);
    -webkit-background-clip: text;
    background-clip: text;
    border-right: 1.4px solid #0ff575;
  }
  
  /* 📌 Logout del Sidebar */
 

  .sidebar-log-out-wrapper {
    background-color: #121f2d;
    align-items: center;
    padding-top: 20px;
    text-decoration: none;
    display: flex;
    position: absolute;
    inset: auto auto 35px 35px;
  }
  
  .sidebar-log-out-image {
    width: 17px;
    margin-right: 10px;
  }
  
  .sidebar-log-out-image-text-block {
    color: #a8a5b8;
    font-size: 16px;
    font-weight: 500;
  }
  
  /* 📌 Contenido del Header */
  .header-content {
    justify-content: space-between;
    align-items: center;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
  }
  
  /* 📌 Toggle y Título */
  .header-toggle-wrapper {
    align-items: center;
    display: flex;
  }
  
  .header-title {
    color: #fff;
    font-size: 24px;
    line-height: 34px;
    margin: 0;
  }
  
  /* 📌 Contenido a la derecha del Header */
  .header-right-content {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  
  /* 📌 Input de Búsqueda */
  .header-search-wrapper {
    margin-right: 50px;
  }
  
  .header-search {
    align-items: center;
    display: flex;
  }
  
  .header-search-input {
    color: #a5a5b8;
    background-color: transparent;
    border: 1px solid #32305a;
    padding: 8px 12px;
    font-size: 15px;
    border-radius: 5px;
  }
  
  /* 📌 Notificaciones */
  .notification-dropdown {
    margin-right: 30px;
    position: relative;
  }
  
  .notification-dropdown-toggle {
    padding: 10px;
    cursor: pointer;
  }
  
  .bell-number {
    color: #fff;
    background-color: #4d88ff;
    border-radius: 100%;
    width: 14px;
    height: 14px;
    font-size: 9px;
    font-weight: 700;
    display: flex;
    position: absolute;
    top: -5px;
    right: -5px;
  }
  
  .notification-dropdown-list {
    background-color: #2a263e;
    border-radius: 8px;
    width: 260px;
    padding: 10px;
    position: absolute;
    top: 50px;
    right: 0;
    display: none;
  }
  
  .notification-dropdown-list.w--open {
    display: block;
    border: 1px solid #32305a;
  }
  
  .notification-singel-dropdown {
    align-items: center;
    padding: 10px 15px;
    text-decoration: none;
    display: flex;
  }
  
  .hero-images {
    width: 30px;
  }
  
  .notification-hero-text {
    width: 170px;
    margin-left: 15px;
  }
  
  .hero-info-wrapper {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
    display: flex;
  }
  
  .notification-hero-name {
    color: #fff;
    font-size: 12px;
  }
  
  .notification-hero-name-text-block {
    color: #a5a5b8;
    font-size: 12px;
  }
  
  /* 📌 Dropdown de Perfil */
  .header-profile-dropdown-toggle {
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .profile-image {
    margin-right: 15px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  
  .profile-dropdown-list {
    background-color: #132436;
    border-radius: 8px;
    width: 140px;
    margin-top: 10px;
    right: 0;
    position: absolute;
    display: none;
  }
  
  .profile-dropdown-list.w--open {
    display: block;
    border: 1px solid #32305a;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .list-item {
    color: #a5a5b8;
    background-color: #132436;
    align-items: center;
    padding: 10px;
    text-decoration: none;
    display: flex;
  }
  
  .item-text {
    margin-left: 10px;
  }
  
  .w-inline-block {
    max-width: 100%;
    /* display: inline-block; */
  }

  .sidebar-menu-link-icon-wrap {
    margin-right: 15px;
    font-family: "Fa solid 900", sans-serif;
    font-size: 21px;
  }
  
  .sidebar-menu-link-icon-wrap.mr0 {
    margin-right: 0;
  }