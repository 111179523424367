/* Tema Oscuro para DashboardDeposit */
.deposit-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #1e1e2f;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    font-family: Arial, sans-serif;
    color: #f5f5f5;
  }
  
  .title {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #f5f5f5;
    text-align: center;
  }
  
  .withdraw-select-coin {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #444;
    border-radius: 5px;
    font-size: 1rem;
    background: #2a2a40;
    color: #f5f5f5;
  }
  
  .step-container {
    margin-bottom: 20px;
  }
  
  .options {
    display: flex; /* Alinea los botones en una fila */
    gap: 10px; /* Espaciado entre los botones */
    justify-content: center; /* Centra los botones horizontalmente */
    flex-wrap: nowrap; /* Evita que los botones salten a otra línea */
    overflow-x: auto; /* Agrega scroll horizontal si hay demasiados botones */
  }
  
  .network-button {
    flex: 1; /* Los botones ocuparán el mismo ancho */
    max-width: 150px; /* Tamaño máximo para cada botón */
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background: #007bff;
    color: #f5f5f5;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background 0.3s;
    text-align: center;
    white-space: nowrap; /* Evita que el texto del botón salte de línea */
  }
  
  .network-button:hover {
    background: #0056b3;
  }
  
  .network-button.active {
    background: #28a745;
  }
  
  .details {
    background: #2a2a40;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  }
  
  .details p {
    margin: 5px 0;
    font-size: 1rem;
    color: #dcdcdc;
  }
  
  .copy-button {
    margin-top: 10px;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    background: #007bff;
    color: #f5f5f5;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .copy-button:hover {
    background: #0056b3;
  }
  